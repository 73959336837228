import * as axios from 'axios';
import AppConfig from '../Constants';
import { axiosGetCall, axiosPostCall, axiosPutCall } from './AxiosService';

function procesLocation(newLocation,creationFlag) {
    let jsonObj = {};
    try {
        if(!creationFlag){
            jsonObj.Id = newLocation.Id;
        }
        jsonObj.Name = newLocation.Name;
        jsonObj.Capacity = newLocation.Capacity;
        jsonObj.Description = newLocation.Description;
        jsonObj.Order = newLocation.Order;
        jsonObj.Status = newLocation.StatusId===1?AppConfig.ActivityForm.ActiveStatusId:AppConfig.ActivityForm.InactiveStatusId;
        // jsonObj.Notes = creationFlag?'Project Created by '+ userName:'Project Updated by ' + userName;
    } catch (error) {
        console.log(error);
        jsonObj = false;
    }
    return jsonObj;
}
var LocationService = {
    // SendEmail: () => {
    getActiveLocationEquipmentData() {
        return new Promise((resolve, reject) => {
            axiosGetCall('/api/Location/GetActiveLocationEquipment')
                .then(locationEquipments => {
                    resolve(locationEquipments.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    getAllLocations() {
        return new Promise((resolve, reject) => {
            axiosGetCall('/api/Location')
                .then(allLocations => {
                    resolve(allLocations.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    getLocationById(Id) {
        return new Promise((resolve, reject) => {
            axiosGetCall(`/api/Location/${Id}`)
                .then(allLocations => {
                    resolve(allLocations.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    createLocation(newLocation) {
        return new Promise((resolve, reject) => {
            let payLoadObj = procesLocation(newLocation,true);
            axiosPostCall('/api/Location',{
                    jsonObj: payLoadObj,
                })
                .then(location => {
                    resolve(location.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    updateLocation(newLocation) {
        return new Promise((resolve, reject) => {
            let payLoadObj = procesLocation(newLocation,false);
            axiosPutCall('/api/Location',{
                    jsonObj: payLoadObj,
                })
                .then(location => {
                    resolve(location.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    }
};
export default LocationService;
