import * as React from 'react';
import { IActivity, IActivityLocation } from '../interfaces/IActivityForm';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { IActivitySync, IOutlookSyncFormProps, IOutlookSyncFormState } from '../interfaces/IOutlookSync';
import NewOutlookForm from './NewOutlookForm';
import EditOutLookFrom from './EditOutLookFrom';



export default class OutlookSyncForm extends React.Component<IOutlookSyncFormProps, IOutlookSyncFormState> {  
    constructor(props: IOutlookSyncFormProps) {
        super(props);
        this.state = {
            disabled:false,
            isSynched:false,
            isFormOpen:false,           
        }
    }
    componentDidMount = () =>{
        const {disabled,isSynched} = this.props;
        this.setState({disabled,isSynched});
    }
    componentDidUpdate = (prevProps:IOutlookSyncFormProps, prevState:IOutlookSyncFormState) =>{
        const {disabled,isSynched} = this.props;
        if(prevProps.disabled !== disabled){
            this.setState({
                disabled
            });
        } 
        if(prevProps.isSynched !== isSynched){
            this.setState({
                isSynched
            });
        }
    } 
    private handleFormOpenBtnClick = () =>{
        this.setState((previousState:IOutlookSyncFormState)=>{
            return {
                isFormOpen:!previousState.isFormOpen
            };
        });
    }  
    private handleActSyncSave = (actSyncArr:IActivitySync[],action:string) =>{
        this.setState((previousState:IOutlookSyncFormState)=>{
            return {
                isFormOpen:!previousState.isFormOpen
            };
        });
        this.props.handleActSyncSave(actSyncArr,action);
    }    
    public render() {
        const {isSynched,isFormOpen} = this.state;
        const {disabled} = this.props;
        const iconProps =  isSynched?{iconName:"CalendarReply",styles:{root:{color:"green"}}}:{iconName:"AddEvent"};
        const btnTxt= isSynched?"Pushed to Outlook":"Push to Outlook";
        const formHeading = "Push to Outlook";
        return (
        <div>
             {!isSynched? (<PrimaryButton iconProps={iconProps} disabled={disabled} text={btnTxt} onClick={this.handleFormOpenBtnClick} />):
             (
                <DefaultButton iconProps={iconProps} disabled={disabled} text={btnTxt} onClick={this.handleFormOpenBtnClick} />
             )}
            {isFormOpen &&  (
                <Panel
                    type={PanelType.medium}
                    headerText={formHeading}
                    isBlocking={true}
                    isOpen={isFormOpen}
                    hasCloseButton={true}
                    // isHiddenOnDismiss={true}
                    onDismiss={this.handleFormOpenBtnClick}
                    closeButtonAriaLabel="Close">                    
                    {!isSynched? (
                        <NewOutlookForm {...this.props} handleActSyncSave ={this.handleActSyncSave} handleFormCancelClick={this.handleFormOpenBtnClick}/>
                    ):(
                        <EditOutLookFrom {...this.props} handleActSyncSave ={this.handleActSyncSave} handleFormCancelClick={this.handleFormOpenBtnClick}/>
                    )}
                </Panel>
            )}
        </div>
        );
    }
}
