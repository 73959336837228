import {authProvider, scopes} from '../authProvider';
import CacheManager from './CacheManager';
import * as  _ from 'lodash'; 

var graph = require('@microsoft/microsoft-graph-client');
export async function getAuthenticatedClient(accessToken) {
    let isCahce = false,
        validToken = '',
        newAccInfo = {},
        accountInfo = {};
    try {
        isCahce = CacheManager.isCacheExpired('accountInfo');
    } catch (err) {
        console.log(err);
        isCahce = false;
    }
    if (isCahce && accessToken) {
        validToken = accessToken;
    } else {
        try {
            newAccInfo = await authProvider.acquireTokenSilent(scopes);
            const cacheAccountInfo = CacheManager.getCacheItem('accountInfo');
            accountInfo = JSON.parse(cacheAccountInfo.value);
            accountInfo.jwtAccessToken = newAccInfo.accessToken;
            accountInfo.account = newAccInfo.account;
            CacheManager.updateCacheItem(
                'accountInfo',
                JSON.stringify(accountInfo),
                0.5,
            );
            validToken = newAccInfo.accessToken;
        } catch (err) {
            console.log('account Info error', err);
            newAccInfo = await authProvider.acquireTokenSilent(scopes);
            const cacheAccountInfo = CacheManager.getCacheItem('accountInfo');  
            if(cacheAccountInfo){
                accountInfo = JSON.parse(cacheAccountInfo.value);
                accountInfo.jwtAccessToken = newAccInfo.accessToken;
                accountInfo.account = newAccInfo.account;
                CacheManager.updateCacheItem(
                    'accountInfo',
                    JSON.stringify(accountInfo),
                    0.5
                );
                validToken = newAccInfo.accessToken;
            }      
        }
    }
    // Initialize Graph client
    const client = graph.Client.init({
        // Use the provided access token to authenticate
        // requests
        debugLogging: true,
        authProvider: done => {
            done(null, validToken);
        },
    });
    return client;
}

export function searchForOnlyPeople(searchText, accessToken) {
    const client = getAuthenticatedClient(accessToken);
    return new Promise((resolve, reject) => {
        client
            .api('/users')
            .filter(`startswith(displayName,'${searchText}') and userType  eq 'Member'`)
            .select('displayName,givenName,surname,mail,userPrincipalName,id')
            .top(25)
            .get((err, res) => {
                if (err) {
                    //this._handleError(err);
                    reject(err.message);
                    return;
                }
                //callback(err, (res) ? res.value : []);
                resolve(res ? res.value : []);
            });
    });
}

export async function searchForPeople(searchText, accessToken) {
    const client = await getAuthenticatedClient(accessToken);
    const users = client
        .api('/users')
        .filter(`startswith(displayName,'${searchText}') and userType  eq 'Member'`)
        .select('displayName,givenName,surname,mail,userPrincipalName,id')
        .top(25)
        .get();
    return Promise.all([users]).then(requestData => {
        const newData = [].concat.apply(
            [],
            requestData.map(data => (data.value.length > 0 ? data.value : [])),
        );
        //resolve(requestData);
        return newData;
    }).catch(error =>{
        console.log(error);
        return [];
    });
}
export async function searchForPeopleAndGroups(searchText, accessToken) {
    const client = await getAuthenticatedClient(accessToken);
    const users = client
        .api('/users')
        .filter(`startswith(displayName,'${searchText}') and userType  eq 'Member'`)
        .select('displayName,givenName,surname,mail,userPrincipalName,id')
        .top(25)
        .get();
    const groups = client
        .api('/groups')
        .filter(
            `startswith(displayName,'${searchText}') and groupTypes/any(c:c+eq+'Unified')`,
        )
        .select('displayName,givenName,surname,mail,userPrincipalName,id')
        .top(25)
        .get();
    return Promise.all([users, groups]).then(requestData => {
        const newData = [].concat.apply(
            [],
            requestData.map(data => (data.value.length > 0 ? data.value : [])),
        );
        //resolve(requestData);
        return newData;
    }).catch(error =>{
        console.log(error);
        return [];
    });
}

// GET user/id/photo/$value for each person
export async function getProfilePics(personas, accessToken) {
    const client =  await getAuthenticatedClient(accessToken);
    return new Promise((resolve, reject) => {
        client
            .api(`users/${personas.props.id}/photo/$value`)
            .header('Cache-Control', 'no-cache')
            .responseType('blob')
            .get((err, res) => {
                if (err) {
                    reject(err);
                } else {
                    personas.imageUrl = window.URL.createObjectURL(res);
                    personas.initialsColor = null;
                    resolve(window.URL.createObjectURL(res));
                }
            });
    });
}

// GET me/people
export async function getPeople(accessToken) {
    const client = await getAuthenticatedClient(accessToken);
    return new Promise((resolve, reject) => {
        client
            .api('/me/people')
            .filter("personType/class eq 'Person' and personType/class eq 'Person' and personType/subclass eq 'OrganizationUser'")
            .select(
                'displayName,givenName,surname,scoredEmailAddresses,userPrincipalName',
            )
            .top(25)
            .get((err, res) => {
                if (err) {
                    reject(err.message);
                    return;
                }
                resolve(res ? res.value : []);
                return;
            });
    });
}
export async function searchForMyPeopleAndGroups(accessToken) {
    const client = await getAuthenticatedClient(accessToken);
    const users = client
        .api('/me/people')
        .filter("personType/class eq 'Person'")
        .select('displayName,givenName,surname,scoredEmailAddresses,userPrincipalName,id')
        .top(25)
        .get();
    const groups = client
        .api('/groups')
        .filter(
            `groupTypes/any(c:c+eq+'Unified')`,
        )
        .select('displayName,givenName,surname,mail,userPrincipalName,id')
        .top(25)
        .get();
    return Promise.all([users, groups]).then(requestData => {
        const newData = [].concat.apply(
            [],
            requestData.map(data => (data.value.length > 0 ? data.value : [])),
        );
        //resolve(requestData);
        return newData;
    }).catch(error =>{
        console.log(error);
        return [];
    });
}
export async function createCalendarEvent(calDetails, accessToken) {
    const client = await getAuthenticatedClient(accessToken);
    return new Promise((resolve, reject) => {
        client
            .api('/me/calendar/events')
            .post(calDetails,(err, res) => {
                if (err) {
                    reject(err.message);
                    return;
                }
                resolve(res ? res : []);
            });
    });
}
function sendBatchResults(req, res){
    var responseArr = [];
    for (let reqI = 0; reqI < req.length; reqI++) {
        const request = req[reqI];
        var filteredResponseArr = _.filter(res,{id:request.id.toString()});
        if(filteredResponseArr && filteredResponseArr.length>0){
            request.Status = filteredResponseArr[0].status;
            request.response = filteredResponseArr[0].body;
            responseArr.push(request);
        }
    }
    return responseArr;
}

export async function processSingleBatchRequest(batchJSON,accessToken){
    const client = await getAuthenticatedClient(accessToken);
    return new Promise((resolve, reject) => {
        client.api('$batch').post(batchJSON,(err, res) => {
                if (err) {
                    reject(err.message);
                    return;
                }
                resolve(res ? sendBatchResults(batchJSON.requests,res.responses) : []);
            });
    });
}
export async function createBatchRequest(batchJSON,accessToken){
    const client = await getAuthenticatedClient(accessToken);
    if(batchJSON.requests.length>4){
        let responseArr = [];
        let chunks = _.chunk(batchJSON.requests,4);
        for (let index = 0; index <chunks.length; index++) {
            let eachBatch = chunks[index];
            let response  =  await client.api('$batch').post({requests:eachBatch});
            if(response && response.responses.length>0){
                let results = sendBatchResults(eachBatch,response.responses)
                responseArr = responseArr.concat(results);
            }
            else{
                throw new Error("Sync Failed in Response identification");
            }
        }
        return responseArr;
    }
    else{
        return processSingleBatchRequest(batchJSON,accessToken);
    }
    
}
