import { axiosPostCall, axiosPutCall } from './AxiosService';

const moment = require('moment');
function processDeleteActivitySync(id,actUser) {
    let jsonObj = {};
    try {
        jsonObj.ActivityId = id;
        jsonObj.EventSyncedBy = actUser;
        jsonObj.Notes = "Activity Sync removed for user:" + actUser  ;
    } catch (error) {
        console.log(error);
        jsonObj = false;
    }
    return jsonObj;
}
function processDeleteExpActivitySync(id,actUser,isRemoveAll) {
    let jsonObj = {};
    try {
        jsonObj.ExperimentId = id;
        jsonObj.EventSyncedBy = actUser;
        jsonObj.isRemoveAll = isRemoveAll?1:0;
        jsonObj.SendEmail = isRemoveAll?0:1;
        jsonObj.EmailFlag = 'DeleteEmail';
        jsonObj.Notes = "Experiment remove all  activity sync by user:" + actUser;
    } catch (error) {
        console.log(error);
        jsonObj = false;
    }
    return jsonObj;
}
var ActivitySyncService = {
    cloneActivitySync(jsonObj) {
        return new Promise((resolve, reject) => {
            axiosPostCall(`/api/ActivitySync/Clone`,jsonObj)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    getGridActivitySyncForUser(actObj) {
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/ActivitySync/GetSyncRecordsForGridByUser', {
                    jsonObj: actObj,
                })
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.log(error);
                    reject(error.response);
                });
        });
    }, 
    getActivitySyncForUser(actObj) {
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/ActivitySync/GetSyncRecordsByUser', {
                    jsonObj: actObj,
                })
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.log(error);
                    reject(error.response);
                });
        });
    }, 
    getExpActivitySyncForUser(actObj) {
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/ActivitySync/GetExpSyncRecordsByUser', {
                    jsonObj: actObj,
                })
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.log(error);
                    reject(error.response);
                });
        });
    },
    deleteActivitySyncRecordById(id,actUser) {        
        return new Promise((resolve, reject) => {
            let payLoadObj = processDeleteActivitySync(id,actUser);
            if (!payLoadObj) {
                let msg = 'Error in processing Data. ActivitySync Data not found';
                reject(msg);
            }
            axiosPutCall(`/api/ActivitySync/Delete`,{jsonObj:payLoadObj})
                .then(actSyncRecord => {
                    resolve(actSyncRecord.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    deleteActivitySyncRecordByExpId(id,actUser,isRemoveAll) {        
        return new Promise((resolve, reject) => {
            let payLoadObj = processDeleteExpActivitySync(id,actUser,isRemoveAll);
            if (!payLoadObj) {
                let msg = 'Error in processing Data. ActivitySync Data not found';
                reject(msg);
            }
            axiosPutCall(`/api/ActivitySync/ExpSyncDelete`,{jsonObj:payLoadObj})
                .then(actSyncRecord => {
                    resolve(actSyncRecord.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    createActivitySyncRecord(activityObj) {
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/ActivitySync', {
                jsonObj: activityObj,
            })
            .then(activity => {
                resolve(activity.data);
            })
            .catch(error => {
                console.log(error.response);
                reject(error.response);
            });
        });
    }, 
    createBatchActivitySyncRecord(events,currUserEmail) {
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/ActivitySync/ActSyncBatch', {
                jsonObj: {Events:events, UpdatedBy:currUserEmail}                
            })
            .then(activity => {
                resolve(activity.data);
            })
            .catch(error => {
                console.log(error.response);
                reject(error.response);
            });
        });
    },
    updateActivitySyncRecord(activityObj) {
        return new Promise((resolve, reject) => {
            axiosPutCall('/api/ActivitySync', {
                    jsonObj: activityObj,
                })
                .then(activity => {
                    resolve(activity.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    CloneIndividualActivitySync(actObj){
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/ActivitySync/Clone', actObj)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.log(error);
                    reject(error.response);
                });
        });
    },
    async CallActDeleteFunction(actId){
        try {
            const responseData = axiosPostCall('/api/ActivitySync/ActDelete', {ActId: actId,Notes:"Activity Deleted."});
            const result = await responseData.json();
            console.log(result);
        } catch (error) {
            console.log(error);
        }
    },
    async CallActUpdateFunction(actId,ExperimentId,shiftTimmings,updatedBy){
        try {
            const responseData = axiosPostCall('/api/ActivitySync/ActUpdate', {ActId: actId,ExperimentId:ExperimentId, ShiftTimmings: shiftTimmings, Notes:"Activity Updated",UpdatedBy:updatedBy});
            const result = await responseData.json();
            console.log(result);
        } catch (error) {
            console.log(error);
        }
    }
};
export default ActivitySyncService;
