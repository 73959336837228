import *as React from 'react';
import './Home.css';
import {HomePageConfig} from './HomeConfig';
import PageTiles from '../Components/Common/PageTiles';
import {Redirect} from 'react-router-dom';
import { IHomePageConfigArr, IHomePageLink } from '../Components/Common/IHomeButton';
import HomeButton from "../Components/Common/HomeButton";
import { IBookedForUsers } from '../Main/IMainApp';
import SystemAdminService from '../services/systemadmin-service';
import {Depths} from '@uifabric/fluent-theme/lib/fluent/FluentDepths';
import { Spinner } from '@fluentui/react';
import e from 'express';
import _ from 'lodash';
import AppConfig from '../Constants';


export interface IHomeState{
    shouldRedirect:boolean;
    redirectURI:any;
    bookedForUsers:IBookedForUsers[];
    bookedForVisitors:IBookedForUsers[];
    isLoading:boolean;
    pageConfig:IHomePageConfig;
}
export interface IHomeProps{
    handleRedirection:(selectedKey:any)=>void;
}
export interface IHomePageConfig{
    HeaderText ?: string;
    ActionLinks : IHomePageLink[];
    GuideLinks : IHomePageLink[];
}
export class Home extends React.Component<IHomeProps,IHomeState> {
    constructor(props:IHomeProps) {
        super(props);
        this.state ={
            shouldRedirect: false,
            redirectURI:'/Home',
            pageConfig:this.getHomePageConfig(),
            bookedForUsers:[] as IBookedForUsers[],
            bookedForVisitors:[] as IBookedForUsers[],
            isLoading:true
        };
        this.props.handleRedirection('0');
    }
    componentDidMount = () =>{
        const today = new Date();
        const {pageConfig} = this.state;
        var promisesArr:any[] = [];
        promisesArr.push(this.getBookedForUsers({TodayDate:today}));
        promisesArr.push(this.getHomePageConfigDev());
        Promise.all(promisesArr).then((responses)=>{
            if (responses.length > 0) {
                let bookedForUsersArr:IBookedForUsers[] = responses[0];
                let bookedForUsers:IBookedForUsers[] = bookedForUsersArr.length>0?_.uniqBy(bookedForUsersArr.filter((b)=>{return b.IsEmployee}),'BookedForEmail'):[];
                let bookedForVisitors:IBookedForUsers[] = bookedForUsersArr.length>0?_.uniqBy(bookedForUsersArr.filter((b)=>{return !b.IsEmployee}),'BookedForEmail'):[];
                let homePageConfigArr:IHomePageConfigArr[] = [] as IHomePageConfigArr[];
                homePageConfigArr = responses[1];
                let pageConfigUpdated:IHomePageConfig = this.formaHomePageConfig(homePageConfigArr,pageConfig);
                this.setState({
                    bookedForUsers,
                    bookedForVisitors,
                    pageConfig:pageConfigUpdated,
                    isLoading:false
                });
            }
        }).catch((error)=>{
            this.setState({
                isLoading:false
            });
        });
    }
    getBookedForUsers = (jsonObj:any) :Promise<IBookedForUsers[]|any>=> {
        return new Promise((resolve, reject) => {
            SystemAdminService.getBookedForUsers(jsonObj)
            .then((bookedForUsers:IBookedForUsers[]) => resolve(bookedForUsers))
            .catch(error => {
                reject(error);
            });
        });
    }  
    getHomePageConfigDev = () :Promise<IHomePageConfig[]>=> {
        return new Promise((resolve, reject) => {
            SystemAdminService.getHomePageConfig()
            .then((homePageConfig:any[]) => resolve(homePageConfig))
            .catch(error => {
                reject(error);
            });
        });
    } 
    formaHomePageConfig(homePageConfig: IHomePageConfigArr[],pageConfig:IHomePageConfig): IHomePageConfig {
        let configObj:IHomePageConfig = pageConfig;
        let activeHomePageConfig = homePageConfig.filter((p:IHomePageConfigArr) => {return p.StatusId === AppConfig.ActivityForm.ActiveStatusId;} );
        try {
            if(activeHomePageConfig.length>0){
                let headingEntry =  activeHomePageConfig.filter((h) =>{ return h.SectionName ==="Heading"});
                let headingDetails  = headingEntry[0].Description;
                let actionEntries =  activeHomePageConfig.filter((h) =>{ return h.SectionName ==="ActionLinks"});
                actionEntries.sort((a:IHomePageConfigArr, b:IHomePageConfigArr):any=>{return a.Order - b.Order;});
                let guideLinks =  activeHomePageConfig.filter((h) =>{ return h.SectionName ==="GuideLinks"});
                guideLinks.sort((a:IHomePageConfigArr, b:IHomePageConfigArr):any=>{return a.Order - b.Order;});
                configObj.HeaderText = headingDetails;
                configObj.ActionLinks = actionEntries;
                configObj.GuideLinks = guideLinks;
            }else{
                configObj = this.getHomePageConfig();
            }
            
        } catch (error) {
            configObj = this.getHomePageConfig();
        }
        return configObj;
    }
    handlePageTileClick = (item: IHomePageLink) => {
        this.setState({
            shouldRedirect:true,
            redirectURI:item.LinkURL 
        });
        this.props.handleRedirection(item.LinkURL);
    };
    getHomePageConfig = () =>{
     try {
        var homePageConfig = JSON.parse(process.env.REACT_APP_HOME_PAGE_CONFIG?process.env.REACT_APP_HOME_PAGE_CONFIG:'');
        if (!homePageConfig) {
            return HomePageConfig as IHomePageConfig;
        }else
        {
            return homePageConfig as IHomePageConfig; //JSON.parse(homePageConfig);
        } 
     } catch (error) {
         console.log(error);
         return HomePageConfig;
     }
    }
    render() {
        const {shouldRedirect,redirectURI,bookedForUsers,bookedForVisitors,isLoading,pageConfig} = this.state;
        const columnWidth = 12 / pageConfig.ActionLinks?.length;
        const actionLinkColumnClass = `ms-Grid-col ms-sm12 ms-md${columnWidth} ms-lg${columnWidth}`;
        return (
            <div className="ms-Grid Home" dir="ltr">
                    <div className="ms-Grid-row" style={{textAlign: "left", paddingTop:"20px"}}>
                        {pageConfig.HeaderText && (
                                <div className="ms-Grid-col ms-sm12" style={{fontSize: "14pt", paddingTop:"10px"}} dangerouslySetInnerHTML={{__html: pageConfig.HeaderText}}></div>
                            )}
                    </div>
                    <div className="ms-Grid-row" style={{paddingTop:"18px"}}>
                        <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg3">
                            {pageConfig.ActionLinks.map((_link: IHomePageLink, _ind):any => {
                                return <HomeButton 
                                    key={_ind} link={ _link } 
                                    handlePageTileClick={this.handlePageTileClick}></HomeButton>
                                })}   
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <div className="ms-Grid">
                                <div className="ms-Grid-row" style={{marginBottom:'10px'}}>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{padding:0}}>
                                        <div className='ms-Grid UserTile'  style={{boxShadow: Depths.depth16, minHeight:"65px", padding:5,margin:0,border:'2px solid gray'}}>
                                            <div className="ms-Grid-row" style={{width:"100%"}}>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 usersCard">
                                                    {isLoading?<Spinner/>:bookedForUsers.length}
                                                </div>  
                                            </div>
                                            <div className="ms-Grid-row" style={{width:"100%"}}>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{textAlign:"center"}}>
                                                    Employees
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row" style={{width:"100%"}}>
                                                <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12 userList'>
                                                    <div className='userListDiv'>
                                                        {!isLoading && bookedForUsers.map((user:IBookedForUsers, i:any)=>{
                                                            return(
                                                                <div key={i} className='BookerForUser' >
                                                                    <a href={'mailto: ' + user.BookedForEmail}>{user.BookedForName}</a>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <div className="ms-Grid-row" style={{marginBottom:'10px'}}>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{padding:0}}>
                                        <div className='ms-Grid UserTile'  style={{maxHeight:"300px", boxShadow: Depths.depth16, minHeight:"65px", padding:5, margin:0,border:'2px solid gray'}}>
                                            <div className="ms-Grid-row" style={{width:"100%"}}>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 usersCard">
                                                {isLoading?<Spinner/>:bookedForVisitors.length}
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row" style={{width:"100%"}}>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{textAlign:"center"}}>
                                                    Visitors
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row" style={{width:"100%"}}>
                                                 <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12 userList'>
                                                    <div className='userListDiv'>
                                                        {bookedForVisitors.map((user:IBookedForUsers, i:any)=>{
                                                            return(
                                                                <div key={i} className='BookerForUser' >
                                                                    <a href={'mailto: ' + user.BookedForEmail}>{user.BookedForName}</a>
                                                                </div>
                                                            )}
                                                        )}
                                                    </div>
                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg3">
                            {
                                pageConfig.GuideLinks.map((_link: IHomePageLink, _ind) => {
                                    return <HomeButton key ={_ind} link={ _link } handlePageTileClick={this.handlePageTileClick}></HomeButton>;
                                })
                            }
                        </div>
                    </div>
                    {shouldRedirect?<Redirect to={redirectURI} />:""}
            </div>
        );
    }
}
