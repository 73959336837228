import _ from 'lodash';
import { axiosGetCall, axiosPostCall } from './AxiosService';


function processShiftLocationAvailability(slaMultiArr) {
    var overCapActivityIdArr = [];
    if(slaMultiArr && slaMultiArr.length>0){
        for (let eachSlaArr = 0; eachSlaArr < slaMultiArr.length; eachSlaArr++) {
            const slaArr = slaMultiArr[eachSlaArr];
            if(slaArr.length>0){
               var singleOverCapActivityIdArr =  _.map(slaArr,'Id');
               overCapActivityIdArr =overCapActivityIdArr.concat(singleOverCapActivityIdArr);
            }
        }
        //console.log("overCapActivityIdArr",_.uniq(overCapActivityIdArr));
    }
    return _.uniq(overCapActivityIdArr);
}
let ActivityGridService = {
    getActivitiesForUser(userEmail, statusId, experimentId) {
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/ActivityGrid/GetActivityGridForUser', {
                    "UserEmail": userEmail,
                    "StatusId": statusId,
                    "ExperimentId": experimentId
                })
                .then(activityGridData => {
                    resolve(activityGridData);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    getActivitiesForFilter(userEmail, projectIds,FromDate, ToDate) {
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/ActivityGrid/GetActivityGridByFilter', {
                    "UserEmail": userEmail,
                    "ProjectIds": projectIds,
                    "FromDate": FromDate,
                    "ToDate": ToDate
                })
                .then(activityGridData => {
                    resolve(activityGridData);
                })
                .catch(error => {
                    console.log(error);
                    reject(error.response);
                });
        });
    },   
    getActForFilter(userEmail, projectIds,FromDate, ToDate) {
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/ActivityGrid/GetActGridByFilter', {
                    "UserEmail": userEmail,
                    "ProjectIds": projectIds,
                    "FromDate": FromDate,
                    "ToDate": ToDate
                })
                .then(activityGridData => {
                    resolve(activityGridData);
                })
                .catch(error => {
                    console.log(error);
                    reject(error.response);
                });
        });
    },
    GetExpWithoutActivitiesGridForUser(userEmail, statusId, experimentId) {
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/ActivityGrid/GetExperimentGridForUser', {
                    "UserEmail": userEmail,
                    "StatusId": statusId,
                    "ExperimentId": experimentId
                })
                .then(activityGridData => {
                    resolve(activityGridData);
                })
                .catch(error => {
                    console.log(error);
                    reject(error.response);
                });
        });
    },
    getGridShiftLocationAvaliability() {
        return new Promise((resolve, reject) => {
            axiosGetCall('/api/Shifts/GetGridLocationAvailability', )
                .then(shiftLocationAvailabilities => {
                    resolve(processShiftLocationAvailability(shiftLocationAvailabilities.data));
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    getActivitiesForExp(exptIds, getActive) {
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/ActivityGrid/GetActivitiesForExp', {
                    "ExptIds": exptIds,
                    "GetActive": getActive
                })
                .then(activities => {
                    resolve(activities);
                })
                .catch(error => {
                    console.log(error);
                    reject(error.response);
                });
        });
    },
};
export default ActivityGridService;