import * as axios from 'axios';
import AppConfig from '../Constants';
import { axiosGetCall, axiosPostCall, axiosPutCall } from './AxiosService';

function processShiftLocationAvailability(activityObj) {
    let jsonObj = {};
    try {
        jsonObj.ActivityDate = activityObj.activityDate
            ? activityObj.activityDate
            : '';
    } catch (error) {
        console.log(error.response);
        jsonObj = false;
    }
    return jsonObj;
}
function procesShift(newShiftState,creationFlag) {
    let jsonObj = {};
    try {
        if(!creationFlag){
            jsonObj.Id = newShiftState.editShift.Id;
            jsonObj.Name = newShiftState.editShift.Name;
            jsonObj.DisplayName = newShiftState.editShift.DisplayName;
            jsonObj.ShortName = newShiftState.editShift.ShortName;
            jsonObj.ExtraName = newShiftState.editShift.ExtraName;
            jsonObj.Order = newShiftState.editShift.Order;
            jsonObj.StartHour = newShiftState.StartHour;
            jsonObj.StartHour = newShiftState.StartHour;
            jsonObj.StartMin = newShiftState.StartMin;
            jsonObj.EndHour = newShiftState.EndHour;
            jsonObj.EndMin = newShiftState.EndMin;
            jsonObj.Status = newShiftState.editShift.Status?AppConfig.ActivityForm.ActiveStatusId:AppConfig.ActivityForm.InactiveStatusId;
        }else{
            jsonObj.Name = newShiftState.newShift.Name;
            jsonObj.DisplayName = newShiftState.newShift.DisplayName;
            jsonObj.StartHour = newShiftState.StartHour;
            jsonObj.StartMin = newShiftState.StartMin;
            jsonObj.ShortName = newShiftState.newShift.ShortName;
            jsonObj.ExtraName = newShiftState.newShift.ExtraName;
            jsonObj.Order = newShiftState.newShift.Order;
            jsonObj.EndHour = newShiftState.EndHour;
            jsonObj.EndMin = newShiftState.EndMin;
            jsonObj.Status = newShiftState.newShift.Status?AppConfig.ActivityForm.ActiveStatusId:AppConfig.ActivityForm.InactiveStatusId;
        }
    } catch (error) {
        console.log(error);
        jsonObj = false;
    }
    return jsonObj;
}
var ShiftService = {
    // SendEmail: () => {
    getShiftsData() {
       return new Promise((resolve, reject) => {
            axiosGetCall('/api/Shifts')
                .then(shifts => {
                    resolve(shifts.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    getShiftById(Id) {
       return new Promise((resolve, reject) => {
            axiosGetCall(`/api/Shifts/${Id}`)
                .then(shifts => {
                    resolve(shifts.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    createShift(newShift) {
        let payLoadObj = procesShift(newShift,true);
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/Shifts',{
                    jsonObj: payLoadObj,
                })
                .then(shifts => {
                    resolve(shifts.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    updateShift(newShift) {
        let payLoadObj = procesShift(newShift,false);
        return new Promise((resolve, reject) => {
            axiosPutCall('/api/Shifts',{
                    jsonObj: payLoadObj,
                })
                .then(shifts => {
                    resolve(shifts.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    getShiftLocationAvaliability(activityObj) {
        let payLoadObj = processShiftLocationAvailability(activityObj);
        return new Promise((resolve, reject) => {
           if (!payLoadObj) {
                let msg = 'Error in processing Data. Activity Date not found';
                reject(msg);
            }
            axiosPostCall('/api/Shifts/GetLocationAvailability', {
                    jsonObj: payLoadObj,
                })
                .then(shiftLocationAvailabilities => {
                    resolve(shiftLocationAvailabilities.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    }   
};
export default ShiftService;
