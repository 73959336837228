import { IActivityGridModel } from "../../ActivityGrid/interfaces/IActivityGridModel";
import { IActivityShift } from "../interfaces/IActivityForm";
import { IActivitySync, IOutlookBody, IOutlootEvent, IShiftTimings } from "../interfaces/IOutlookSync";
import { SyncService } from "./SyncService";
import { v4 as uuidv4 } from 'uuid';

export const ExperimentActOutlookSync = {
    formInsertActSyncObj :(activityDetails: any): any =>{
        let actSyncObj:IActivitySync = {} as IActivitySync;
        try {
            actSyncObj.ActivityId = activityDetails.Id?activityDetails.Id:0;
            actSyncObj.ExperimentId = activityDetails.ExperimentId?activityDetails.ExperimentId:null;
            actSyncObj.EventTitle = activityDetails.subject;
            actSyncObj.EventBody = activityDetails.Body;
            actSyncObj.EventStartTime = activityDetails.StartTime;
            actSyncObj.EventEndTime = activityDetails.EndTime;
            actSyncObj.EventSyncedBy = activityDetails.EmailAddress;
            actSyncObj.TransactionId = activityDetails.TransactionId;
            actSyncObj.StatusId = 1;
            actSyncObj.JobStatus ="ToInsert"
            return actSyncObj;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    getLocationFromStr: (locName:string):string =>{
        var LocNameArr = locName.split("|");
        return LocNameArr.join();
    },
    formInsertExpActSyncArray : (expActs:IActivityGridModel[],currUserEmail:string,Shifts:IActivityShift[]): IActivitySync[] => {
        let actSyncObjArr:IActivitySync[] =[];
        for (let index = 0; index < expActs.length; index++) {
            const expAct = expActs[index];
            let locationString:string = ExperimentActOutlookSync.getLocationFromStr(expAct.LocationName);
            let subject = expAct.ActivityName +" ("+locationString+")"
            let shiftTimings = SyncService.getShiftTimingsArr( expAct.ActivityDate,expAct.SelectedShifts , Shifts);
            let clubbedShifts = SyncService.clubShiftTimings(shiftTimings);
            try {                
                for (let shift = 0; shift < clubbedShifts.length; shift++) {
                    let actSyncObj:IActivitySync = {} as IActivitySync;
                    const shiftTiming = clubbedShifts[shift];
                    actSyncObj.ActivityId = expAct.ActivityId;
                    actSyncObj.ExperimentId = expAct.ExperimentId;
                    actSyncObj.EventId = "";
                    actSyncObj.EventTitle = subject;
                    actSyncObj.EventBody = expAct.ActivityDescription ;
                    actSyncObj.EventSyncedBy =  currUserEmail;
                    actSyncObj.EventStartTime = shiftTiming.StartTime;
                    actSyncObj.EventEndTime = shiftTiming.EndTime;
                    actSyncObj.StatusId = 1;
                    actSyncObj.JobStatus ="ToInsert"
                    actSyncObj.TransactionId = uuidv4();
                    actSyncObjArr.push({...actSyncObj});
                }                
            } catch (error) {
                console.log(error);                
            }
        }
        return actSyncObjArr;
    },
    pushAllActivities : (expActs:IActivityGridModel[],currUserEmail:string,Shifts:IActivityShift[]):any =>{
        let actSyncArr : IActivitySync[] =  ExperimentActOutlookSync.formInsertExpActSyncArray(expActs,currUserEmail,Shifts);
        return new Promise((resolve, reject) => {
            SyncService.createBatchActivitySyncRecord(actSyncArr, currUserEmail).then((actSyncRecord:any) => {
                console.log(actSyncRecord);
                resolve(actSyncRecord);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    removeAllActSync : (expId:number, currUserEmail:string,isRemoveAll:boolean):any =>{
        return new Promise((resolve, reject) => {
            SyncService.purgeMultipleExpActivitySyncRecord(expId, currUserEmail,isRemoveAll).then((actSyncRecord:any) => {
                console.log(actSyncRecord);
                resolve(actSyncRecord);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
        });
    }
}