import * as React from 'react';
import {  ActivityGridContext } from '../../Main/MainApp';
import { IActivityGridModel } from './interfaces/IActivityGridModel';
import ActivityGridService from '../../services/activity-grid-service';
import _ from 'lodash';

import { groupAndSortGridItems } from './GroupGridItems';
import {IGroup} from 'office-ui-fabric-react/lib/DetailsList';
import ExperimentGrid from './ExperimentGrid';
import { ActivityGrid } from './ActivityGrid';
import ActivityGridFilters, { IFilterData } from './ActivityGridFilters';
import ExperimentService from '../../services/experiment-service';
import { IProject } from '../Experiment/interfaces/IExperiment';
import {
    MessageBar,
    MessageBarType,
} from 'office-ui-fabric-react/lib/MessageBar';
import { group, info } from 'console';
import { formatActivityDate, getShiftData } from '../Activity/ActivityService';
import {Spinner, SpinnerSize} from 'office-ui-fabric-react/lib/Spinner';
import { IActivityShift, IUserPersonaField } from '../Activity/interfaces/IActivityForm';
import { UserInfo } from 'os';
import AppConfig from '../../Constants';
import { IActivitySync } from '../Activity/interfaces/IOutlookSync';
import { SyncService } from '../Activity/OutLookEventSync/SyncService';
import { v4 as uuidv4 } from 'uuid';

export interface IActivityGridWrapperProps {
    userContext:any;
    filterProps:IFilterData;
    handleFilterChange: (filterData:IFilterData) => void;
}

export interface IActivityGridWrapperState {
    activities: IActivityGridModel[],
    groups: IGroup[];
    sLAData: number[];
    Projects: IProject[];
    isLoading: boolean;
    gridLabel: string;
    showAllActivities: boolean;
    showAllActivitiesLoading: boolean;
    errorMessage: string;
    Message: string;
    gridKey:any;
    filterKey:any;
    showMessage:boolean;
    MsgType:MessageBarType;
    ShowFilters:boolean;
    FilterApplied:boolean;
    FilterValues:IFilterData;
    ActivitySyncData:IActivitySync[];
    ActivitySyncIdArr:number[];
    ShiftTimmingsArr:IActivityShift[];
}

const initialGridLabel = "Activites";
export default class ActivityGridWrapper extends React.Component<IActivityGridWrapperProps, IActivityGridWrapperState> {
    
  constructor(props: IActivityGridWrapperProps) {
    super(props);
        this.state = {
            activities: [],
            groups: [],
            sLAData:[],
            Projects:[],
            isLoading: false,
            gridLabel: initialGridLabel,
            showAllActivities: false,
            showAllActivitiesLoading: false,
            errorMessage:'',
            showMessage:false,
            Message:'',
            MsgType:MessageBarType.info,
            gridKey:uuidv4(),
            filterKey:uuidv4(),
            ShowFilters:false,
            FilterApplied:false,
            FilterValues:{
                FromDate:new Date(formatActivityDate()),
                ToDate:null,
                ProjectId:[],
                User: [],
                hidePastAct:true,
                isFilterApplied:false
            },
            ActivitySyncData:[] as IActivitySync[],
            ActivitySyncIdArr:[] as number[],
            ShiftTimmingsArr:[] as IActivityShift[]
        }
    }
    cancelApiRequest:boolean = false;
    componentDidMount(){
        const {userContext,filterProps} = this.props;
        var CurUserDetails = [{UserLogin:userContext.userName,DisplayName:userContext.name,Email:userContext.userName}];
        let filterValues:IFilterData = {
            FromDate:filterProps.FromDate?filterProps.FromDate:new  Date(formatActivityDate()),
            ToDate:filterProps.ToDate?filterProps.ToDate:null,
            User:filterProps.isFilterApplied?filterProps.User:CurUserDetails,
            ProjectId:filterProps.ProjectId?filterProps.ProjectId:[],
            hidePastAct:filterProps.isFilterApplied?filterProps.hidePastAct:true,
            isFilterApplied:filterProps.isFilterApplied
        };
        this.setState({
            FilterValues:filterValues
        });
        this.loadMasterData();
        this._getInitialDataFromDB(filterValues);
    } 
    componentWillUnmount = () =>{
        this.cancelApiRequest = true;
    }
    componentDidUpdate = (prevProps:IActivityGridWrapperProps,prevState:IActivityGridWrapperState) =>{
        const {filterProps} = this.props;
        let isRealUpdate:boolean = false;
        if(prevProps.filterProps.hidePastAct !== filterProps.hidePastAct){           
            isRealUpdate= true;
        }
        if(prevProps.filterProps.FromDate !== filterProps.FromDate){           
          isRealUpdate= true;
        }
        if(prevProps.filterProps.ToDate !== filterProps.ToDate){           
            isRealUpdate= true;
        }
        if(this.CheckProjectField(prevProps.filterProps.ProjectId,filterProps.ProjectId)||this.CheckProjectField(filterProps.ProjectId, prevProps.filterProps.ProjectId)){
            isRealUpdate= true;
        }
        if(this.CheckPersonsInField(prevProps.filterProps.User,filterProps.User) || this.CheckPersonsInField(filterProps.User, prevProps.filterProps.User)){
            isRealUpdate= true;
        }
        if(isRealUpdate){
            this.setState({
                FilterValues:{...filterProps}
            });
            this._getInitialDataFromDB(filterProps);
        }
    }
    CheckPersonsInField = (prevPeople :IUserPersonaField[]|null,CurrPeople: IUserPersonaField[]|null) =>{
      let flag:boolean = false;
      var currPeopleEmail:string[] = CurrPeople?_.map(CurrPeople,'Email'):[];
      if(prevPeople){
          for (let people = 0; people < prevPeople.length; people++) {
              const prevPerson:IUserPersonaField = prevPeople[people];
              if(currPeopleEmail.indexOf(prevPerson.Email) === -1){
                  flag = true;
              }
          }
      }
      return flag;
    }
    CheckProjectField = (prevProject :string[]|null,CurrProject: string[]|null) =>{
      let flag:boolean = false;
      if(prevProject){
          for (let Project = 0; Project < prevProject.length; Project++) {
              const prevPrjctId:string = prevProject[Project];
              if(CurrProject?.indexOf(prevPrjctId) === -1){
                  flag = true;
    
              }
          }
      }
      return flag;
    }
    loadMasterData = () => {
        return new Promise((resolve, reject) => {
          ExperimentService.getProjects().then((res) => {
            let _projects = res.data as IProject[];
            // _projects.push({Id:0,Name:"All"});
           let sortedProjects:IProject[] =  _.sortBy(_projects,'Id');
            this.setState({
              Projects: sortedProjects
            });
            resolve(true);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });   
        });
    }
    setInitialState = (message:string) =>{
        this.setState({
            showMessage:true,
            activities: [],
            groups: [],
            sLAData:[],
            gridLabel: initialGridLabel,
            isLoading: false,
            MsgType:MessageBarType.info,
            Message:message
        });
    }

    _getInitialDataFromDB = (FilterValues:IFilterData) => {
        this.setState({
            isLoading:true,
            showMessage:false
        });   
        const  {userContext} = this.props;
        let currentUser:string = userContext.userName;
        let userEmailArr:string[]|null = FilterValues.User.length>0?_.map(FilterValues.User,'Email'):null;
        let userEmail:any =  userEmailArr?userEmailArr.join(","):null;
        let hidePastAct:boolean =  FilterValues.hidePastAct;
        let toDate:any =  FilterValues.ToDate?formatActivityDate(FilterValues.ToDate):null;
        let fromDate:any =  FilterValues.FromDate?formatActivityDate(FilterValues.FromDate):null;
        if(!toDate ||!fromDate){
            if(hidePastAct){
                fromDate = formatActivityDate();
                toDate =null;
            }
            else{
                toDate=null;
                fromDate=null;
            }
        }
        let projectIds:any =  FilterValues.ProjectId.length>0?FilterValues.ProjectId.join(","):null;
        let promises = [];
        promises.push(ActivityGridService.getActivitiesForFilter(userEmail,projectIds,fromDate,toDate));
        promises.push(ActivityGridService.getGridShiftLocationAvaliability());
        promises.push(getShiftData());
        Promise.all(promises)
        .then(responses => { 
            if(responses[0]!=null){
                if (responses && responses.length > 0) {  
                    let items: IActivityGridModel[] = responses[0].data as IActivityGridModel[];
                    let activityIdArr:number[] =_.uniq(_.map(items,'ActivityId'));
                    let sLAData: number[] = responses[1] as number[];
                    let shiftsRes:IActivityShift[]   = responses[2] as IActivityShift[];
                    let shifts:IActivityShift[] = _.filter(shiftsRes,{StatusId:AppConfig.ActivityForm.ActiveStatusId}) as IActivityShift[];
                    if(items.length>0 && !this.cancelApiRequest){
                        if(!this.state.FilterApplied){
                            SyncService.getGridActivitySyncForUser(activityIdArr,currentUser).then((syncData:any) => {
                                let actSyncRes:IActivitySync[] = syncData as IActivitySync[];
                                let syncActIdArr:number[] =  actSyncRes.length>0?_.map(actSyncRes,'ActivityId'):[];        
                                this.setGridDetails(items,sLAData,shifts);
                                this.setState({
                                    ActivitySyncData:actSyncRes,
                                    ActivitySyncIdArr:syncActIdArr
                                });
                            })
                            .catch(error => {
                                this.setState({
                                    activities: [],
                                    isLoading: false,
                                    showAllActivitiesLoading: false,
                                    errorMessage: "Error Occured!\n"+error 
                                });
                                console.log(error);
                            });
                        }
                    }
                    else{
                        if(!this.cancelApiRequest)
                        this.setInitialState('No activities found.');
                    }
                }
            }
        })
        .catch(error => {
            this.setState({
                activities: [],
                isLoading: false,
                showAllActivitiesLoading: false,
                errorMessage: "Error Occured!\n"+error                
            });
        });
    }
    handleDismissOnClear = () => {
        this.setState({
            filterKey:uuidv4()
        });
    }
    handleApplyFilter = (filterData:IFilterData) => {
        this.setState({
            showMessage: false,
            isLoading: true,
            FilterApplied: true,
            FilterValues:filterData,
            filterKey:uuidv4(),
            gridLabel:initialGridLabel
        });
        filterData.isFilterApplied = true;
        this.props.handleFilterChange(filterData);
        let userEmailArr:string[]|null = filterData.User.length>0?_.map(filterData.User,'Email'):null;
        let userEmail:any =  userEmailArr?userEmailArr.join(","):null;
        let hidePastAct:boolean =  filterData.hidePastAct;
        let toDate:any =  filterData.ToDate?formatActivityDate(filterData.ToDate):null;
        let fromDate:any =  filterData.FromDate?formatActivityDate(filterData.FromDate):null;
        if(!toDate ||!fromDate){
            if(hidePastAct){
                fromDate = formatActivityDate();
                toDate =null;
            }
            else{
                toDate=null;
                fromDate=null;
            }
        }
        let projectIds:any =  filterData.ProjectId.length>0?filterData.ProjectId.join(","):null;
        this.getFilterDataFromDB(userEmail,projectIds,fromDate,toDate);
    }
    getFilterDataFromDB = (userEmail:any,projectIds:any,fromDate:any,toDate:any) =>{
        const  {userContext} = this.props;
        let currentUser:string = userContext.userName;
        let promises = [];
        promises.push(ActivityGridService.getActivitiesForFilter(userEmail,projectIds,fromDate,toDate));
        promises.push(ActivityGridService.getGridShiftLocationAvaliability());
        // promises.push();
        Promise.all(promises)
        // ActivityGridService.getActivitiesForFilter(userEmail, projectIds, fromDate, toDate)
        .then((responses:any)=>{
            if(responses[0]!=null){
                if (responses && responses.length > 0) {  
                    const {ShiftTimmingsArr}  = this.state;
                    let items: IActivityGridModel[] = responses[0].data as IActivityGridModel[];
                    let activityIdArr:number[] = _.uniq(_.map(items,'ActivityId'));
                    let sLAData: number[] = responses[1] as number[];
                    if(items.length>0 && !this.cancelApiRequest){
                        SyncService.getGridActivitySyncForUser(activityIdArr,currentUser).then((syncData:any) => {
                            let actSyncRes:IActivitySync[] = syncData as IActivitySync[];
                            let syncActIdArr:number[] =  actSyncRes.length>0?_.map(actSyncRes,'ActivityId'):[];        
                            this.setGridDetails(items,sLAData,ShiftTimmingsArr);
                            this.setState({
                                ActivitySyncData:actSyncRes,
                                ActivitySyncIdArr:syncActIdArr
                            });
                        })
                        .catch(error => {
                                this.setState({
                                    activities: [],
                                    isLoading: false,
                                    showAllActivitiesLoading: false,
                                    errorMessage: "Error Occured!\n"+error 
                                });
                                console.log(error);
                            });
                    }
                    else{
                        if(!this.cancelApiRequest)
                        this.setInitialState('No activities found with selected filters.');                
                    }
                }
            }
        })
        .catch(error => {
            this.setState({
                activities: [],
                isLoading: false,
                showAllActivitiesLoading: false,
                errorMessage: "Error Occured!\n"+error                
            });
        });
    }
    setGridDetails =(items:any,sLAData:number[],shifts:IActivityShift[])=>{
        let groupedItems = this._groupActivitiesById(items,sLAData);
        let currUserObj = this.props.userContext;
        let gridObj= groupAndSortGridItems(groupedItems,"ExperimentId","Experiment: ","ActivityDate","ExperimentStartDate",currUserObj);
        //add activity count to group labels
        let exptIds: string[] = [];
        exptIds = gridObj.ExperimentIds?.length>0?gridObj.ExperimentIds:[];      
        ActivityGridService.getActivitiesForExp(exptIds.join(","), 1).then((result)=>{
            const _gridObjWithActCount = {...gridObj};                       
            if(result){
                const actArray = result.data as {Id: number; ExperimentId: number}[];
                if(actArray.length>0){
                    _gridObjWithActCount.GroupArr.forEach((g)=>{
                        const actCountTotal = actArray.filter((a)=>{return a.ExperimentId.toString()===g.key}).length;                        
                        const actCountDisp = _gridObjWithActCount.ItemsArr.filter((i)=>{return i.ExperimentId?.toString() === g.key}).length;
                        if(g.name !== AppConfig.ActivityForm.MyStandAloneActStr){
                            g.name = g.name + " (Showing "+  actCountDisp.toString() +" of "+ actCountTotal.toString() +" activities)";
                        }   
                    });
                }
            } 
            let newGridLabel = _gridObjWithActCount.ItemsArr ? initialGridLabel + " ("+ _gridObjWithActCount.ItemsArr.length.toString() +")": "";    
            this.setState({
                activities: _gridObjWithActCount.ItemsArr,
                groups: _gridObjWithActCount.GroupArr,
                sLAData:sLAData,
                isLoading: false,
                showAllActivitiesLoading: false,
                gridLabel: newGridLabel,
                errorMessage: "",
                ShiftTimmingsArr:shifts,
                gridKey:uuidv4()
            });       
        });
    }
    _checkIfGroupedItemExists = (proPValueStr:string,itemToAdd:string) :boolean =>{
        let isPresent = false;
        let valueArr =  proPValueStr?.split(",");
        if(valueArr && valueArr.length>0){
            if(valueArr.indexOf(itemToAdd)!==-1){
                isPresent = true;
            }
        }
        return isPresent
    }
    checkAndGetShiftId = (item: IActivityGridModel, shiftId: number): number[]=>{ 
        let shiftArr:number[] = item.SelectedShifts?item.SelectedShifts:[];
        if(shiftArr.indexOf(shiftId) === -1){
          shiftArr.push(shiftId);
        }
        return shiftArr;
      }   
      checkAndGetLocId = (item: IActivityGridModel, shiftId: number): number[]=>{ 
        let shiftArr:number[] = item.SelectedLocations?item.SelectedLocations:[];
        if(shiftArr.indexOf(shiftId) === -1){
          shiftArr.push(shiftId);
        }
        return shiftArr;
      }  
    _groupActivitiesById = (rawArray: IActivityGridModel[],sLAData:number[]) => {            
        let groupedArr: IActivityGridModel[]=[];
        const {Projects} = this.state;
        rawArray.forEach((item, index)=>{
            //overBooking Indication
            if(sLAData.indexOf(item.ActivityId)!== -1){
                item.IsOverBooked = true;
            }
            else{
                item.IsOverBooked = false;
            }
            if(Projects.length>0 && item.ProjectId){
                var _pRec:any =_.filter(Projects,{Id:item.ProjectId});
                if(_pRec && _pRec.length>0){
                    item.ProjectName = _pRec[0].Name || '';
                }
            }
            if(item.ShiftId === AppConfig.ActivityForm.TBDRejectShiftId){
                item.isTbdSelected = true;
              }
            let groupedItem = groupedArr.filter(i => {return i.ActivityId==item.ActivityId});
            if(groupedItem.length<1){
                item.SelectedShifts = this.checkAndGetShiftId(item,item.ShiftId);
                item.SelectedLocations = this.checkAndGetLocId(item,item.LocationId);
                groupedArr.push(item);
            }
            else{
                if (groupedItem[0].ShiftName != item.ShiftName && item.ShiftName!=null){
                    if(!this._checkIfGroupedItemExists(groupedItem[0].ShiftName,item.ShiftName)){
                        groupedItem[0].ShiftName = groupedItem[0].ShiftName+","+ item.ShiftName;
                    }
                }
                if (groupedItem[0].ShiftShortName != item.ShiftShortName && item.ShiftShortName!=null){
                    let prevName = groupedItem[0].ShiftShortName;
                    let currShift = item.ShiftShortName;
                    if(!this._checkIfGroupedItemExists(prevName,currShift)){
                        groupedItem[0].ShiftShortName = prevName+","+ currShift;
                    }
                } 
                if (groupedItem[0].LocationName != item.LocationName && item.LocationName!=null){
                    if(!this._checkIfGroupedItemExists(groupedItem[0].LocationName,item.LocationName)){
                        groupedItem[0].LocationName = groupedItem[0].LocationName+","+ item.LocationName;
                    }
                }
                if (groupedItem[0].EquipmentName != item.EquipmentName && item.EquipmentName!=null){
                    if(!this._checkIfGroupedItemExists(groupedItem[0].EquipmentName,item.EquipmentName)){
                        groupedItem[0].EquipmentName = groupedItem[0].EquipmentName+"," + item.EquipmentName;
                    }
                }
                groupedItem[0].SelectedShifts = this.checkAndGetShiftId(groupedItem[0],item.ShiftId);
                groupedItem[0].SelectedLocations = this.checkAndGetLocId(groupedItem[0],item.LocationId);
            }
        });
        return groupedArr;        
    }
    /* _getGroups = (items: IActivityGridModel[]) => {        
        let groups: IGroup[]=[];        
        if(items.length > 0){
            items.forEach((item, index)=>{ 
                let _key = item.ExperimentId ? item.ExperimentId.toString() : "noex";
                let _name = item.ExperimentName ? ("Experiment: " + item.ExperimentName) : "Stand-alone Activities";
                let group = groups.filter(g => {return g.key==_key});            
                if(group.length > 0){            
                    group[0].count++;        
                }
                else{         
                    //check if it's experiment owner
                    let showEdit = item.Owner ? item.Owner.toUpperCase()===this.props.userContext.userName.toUpperCase() : false;
                    //check if it's administrator
                    showEdit = showEdit || this.props.userContext.isAdmin;       
                    //check if the group is for standalone activities
                    showEdit = showEdit && _key!=="noex";
                    groups.push({
                        key: _key,
                        name: _name,
                        startIndex: index,
                        count: 1,
                        data: showEdit
                    });                 
                }
            });            
        }
        //get activity count for each group
        return groups;
    } */
    handleActSyncSave = (actSyncArr:any[],action:string) =>{
        const{ActivitySyncData, ActivitySyncIdArr} = this.state;
        if(action === "Push"){
          let updatedArr:IActivitySync[] = ActivitySyncData.concat(actSyncArr);
          let updatedSyncIdArr:number[] = ActivitySyncIdArr; 
          updatedSyncIdArr.push(actSyncArr[0].ActivityId);
          this.setState({
            ActivitySyncData:updatedArr, 
            ActivitySyncIdArr:updatedSyncIdArr
          });
        }
        if(action ==="Delete"){
          if(actSyncArr.length>0){
            let updatedArr:IActivitySync[] = ActivitySyncData.filter(o=>{ return o.ActivityId !== actSyncArr[0].ActivityId});
            let updatedIdArr:number[] = ActivitySyncIdArr.filter(o=>{ return o !== actSyncArr[0].ActivityId});
            this.setState({
              ActivitySyncData:updatedArr,
              ActivitySyncIdArr:updatedIdArr 
            });
          }
        }
        if(action ==="Update"){
          if(actSyncArr.length>0){
            let updatedArr:IActivitySync[] = ActivitySyncData.filter(o=>{ return o.ActivityId !== actSyncArr[0].ActivityId});
            let updatedIdArr:number[] = ActivitySyncIdArr.filter(o=>{ return o !== actSyncArr[0].ActivityId});
            updatedArr = updatedArr.concat(actSyncArr);
            updatedIdArr.push(actSyncArr[0].ActivityId); 
            this.setState({
              ActivitySyncData:updatedArr,
              ActivitySyncIdArr:updatedIdArr 
            });
          }
        }
      }
    renderMsg(msg:string,MsgType:MessageBarType){
        return(
            <div style={{width:"90%",fontSize:16,overflow:"auto",margin:"0 auto",paddingBottom:"10px",paddingTop:"0px"}}>
                <MessageBar messageBarType={MsgType} className={"errorMsgInfo"}>
                    {msg}
                </MessageBar>
            </div>
        );
    }
    public render() {
        const{activities,groups,Projects,sLAData,isLoading,gridLabel,showAllActivities,ActivitySyncData,ActivitySyncIdArr,ShiftTimmingsArr,
            showAllActivitiesLoading,errorMessage,gridKey,filterKey,showMessage,MsgType,Message,FilterValues} =  this.state;
    return (
        <div style={{width:"100%", textAlign:"center",overflow:"auto"}}>        
               <div style={{width:"90%",display:"none"}}>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </p>
                </div>                
                <div style={{width:"90%",overflow:"auto",margin:"0 auto",paddingBottom:"10px",paddingTop:"30px"}}>
                    <ActivityGridFilters key={filterKey}
                        gridLabel={gridLabel} 
                        Projects={Projects} 
                        filterValues={FilterValues}
                        handleApplyFilter={this.handleApplyFilter} 
                        handleDismissOnClear={this.handleDismissOnClear}/>
                </div>
            {showMessage && this.renderMsg(Message,MsgType) }
            {isLoading ?(<Spinner key={"spinner1"} size={SpinnerSize.large} labelPosition={"top"} label="Loading Activities..."></Spinner>):
            (
                <ActivityGrid 
                    activities={activities}
                    ActivitySyncData ={ActivitySyncData}
                    ActivitySyncIdArr ={ActivitySyncIdArr}
                    ShiftTimmingsArr={ShiftTimmingsArr}
                    groups={groups}
                    sLAData={sLAData}
                    isLoading={isLoading}
                    showAllActivities={showAllActivities}
                    showAllActivitiesLoading={showAllActivitiesLoading}
                    errorMessage={errorMessage}
                    handleActSyncSave={this.handleActSyncSave}
                    />
            )}           
        </div>
    );
    }
}
