import * as React from 'react';
import CreateActivityForm from '../Components/Activity/CreateActivityForm';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import AppConfig from '../Constants';
import EditActivityForm from '../Components/Activity/EditActivityForm';
// import EditActivityForm from '../Components/Activity/EditActivityForm';

interface IPlanActivityState{
    isViewForm:boolean;
    isNewForm:boolean;
    itemId:string;
    ParentId:string;
    CloneTime:number;

}
interface IPlanActivityProps{
    handleRedirection:(selectedKey:any)=>void;
}
export class PlanActivity extends React.Component<RouteComponentProps & IPlanActivityProps,IPlanActivityState> {
    constructor(props:RouteComponentProps & IPlanActivityProps) {
        super(props);
        this.state ={
            isViewForm:false,
            isNewForm:true,            
            ParentId: "0",
            CloneTime: 0,
            itemId:'0'
        };
        this.props.handleRedirection('2');
    }    
    componentDidMount() {
        var qString:string = this.props.location?this.props.location.search:'';
        const values = queryString.parse(qString);
        if(values){
            var keyIdValue:string = '0';   
            let _pidValue: string = "0";
            let _CloneTimeParam: number = 0;
            var keyViewFlagValue:boolean = false;   
            for (const key in values) {
                if(key.toUpperCase() === AppConfig.ActivityForm.IdParam.toUpperCase()){
                    keyIdValue = values[key] as string;
                }  
                if(key.toUpperCase() === AppConfig.ActivityForm.ViewParam.toUpperCase()){
                    var keyVal = values[key]as string;
                    keyViewFlagValue = parseInt(keyVal)==1?true:false;
                }
                if(key.toUpperCase() === AppConfig.ActivityForm.ParentIdParam.toUpperCase()){
                    _pidValue = values[key] as string;                                        
                }   
                if(key.toUpperCase() === AppConfig.ActivityForm.CloneTimeParam.toUpperCase()){
                    _CloneTimeParam = parseInt(values[key] as string) ;                                        
                }   
            }
            if(keyIdValue!=='0'){
                this.setState({
                    isNewForm:false,
                    isViewForm:keyViewFlagValue,
                    itemId:keyIdValue,
                    ParentId:_pidValue, 
                    CloneTime:_CloneTimeParam
                });  
            }                    
        }

    }
    render() {
        const {isNewForm,itemId,isViewForm, ParentId,CloneTime} = this.state;
        return (
            <div style={{width:"90%", margin: "0.5em auto"}}>                
               {isNewForm?<CreateActivityForm  />:
               <><EditActivityForm Id={parseInt(itemId)} ParentId={+ParentId} CloneTime={CloneTime} viewForm={isViewForm}/></>}             
            </div>
        );
    }
}
