import * as React from 'react';
import Chip from '@material-ui/core/Chip';
import { IFilterData } from './ActivityGridFilters';
import { IUserPersonaField } from '../Activity/interfaces/IActivityForm';
import _ from 'lodash';
import { IProject } from '../Experiment/interfaces/IExperiment';
import { formatActivityFilterDate } from '../Activity/ActivityService';
export interface IFilterChipsProps {
    FilterData:IFilterData;
    Projects:IProject[];
    handleDeleteFilter:(ev:any) =>void;
}

export interface IFilterChipsState {
    showDateRangeChip:boolean;
    showDateToggleChip:boolean;
    showProjectChip:boolean;
    showUserChip:boolean;
    hidePastAct:boolean;
    showNone:boolean;
}

export default class FilterChips extends React.Component<IFilterChipsProps, IFilterChipsState> {
  constructor(props: IFilterChipsProps) {
    super(props);
    this.state = {
        showDateRangeChip:false,
        showDateToggleChip:false,
        showProjectChip:false,
        showUserChip:false,
        hidePastAct:false,
        showNone:true
    }
}
componentDidMount = () =>{
    const {FilterData} = this.props;
    let userEmailArr:string[]|null = FilterData.User.length>0?[]:null;
    let userEmail:boolean =  userEmailArr?true:false;
    let projectIds:any =  FilterData.ProjectId.length>0?true:false;
    let hidePastAct:boolean =  FilterData.hidePastAct;
    let toDate:any =  FilterData.ToDate?true:false;
    let fromDate:any =  FilterData.FromDate?true:false;
    let dateRange:boolean = false;
    if(!toDate ||!fromDate){
        dateRange= false;
    }else{
        dateRange= true;
    }
    let showNone:boolean = false;
    if(!dateRange && !hidePastAct && !projectIds && !userEmail){
        showNone= true;
    }
    this.setState({
        showDateRangeChip:dateRange,
        showDateToggleChip:hidePastAct,
        showProjectChip:projectIds,
        showUserChip:userEmail,
        hidePastAct:hidePastAct,
        showNone:showNone
    });

  }
componentDidUpdate = (prevProps:IFilterChipsProps,prevState:IFilterChipsState)=>{
        const {FilterData} = this.props;
        let isRealUpdate:boolean = false;
        if(prevProps.FilterData.hidePastAct !== FilterData.hidePastAct){           
          isRealUpdate= true;
        }
        if(prevProps.FilterData.FromDate !== FilterData.FromDate){           
          isRealUpdate= true;
        }
        if(prevProps.FilterData.ToDate !== FilterData.ToDate){           
            isRealUpdate= true;
        }
        if(this.CheckProjectField(prevProps.FilterData.ProjectId,FilterData.ProjectId)||this.CheckProjectField(FilterData.ProjectId, prevProps.FilterData.ProjectId)){
            isRealUpdate= true;
        }
        if(this.CheckPersonsInField(prevProps.FilterData.User,FilterData.User) || this.CheckPersonsInField(FilterData.User, prevProps.FilterData.User)){
            isRealUpdate= true;
        }
        if(isRealUpdate){
            let userEmailArr:string[]|null = FilterData.User.length>0?[]:null;
            let userEmail:boolean =  userEmailArr?true:false;
            let projectIds:any =  FilterData.ProjectId.length>0?true:false;
            let hidePastAct:boolean =  FilterData.hidePastAct;
            let toDate:any =  FilterData.ToDate?true:false;
            let fromDate:any =  FilterData.FromDate?true:false;
            let dateRange:boolean = false;
            if(!toDate ||!fromDate){
                dateRange= false;
            }else{
                dateRange= true;
            }
            let showNone:boolean = false;
            if(!dateRange && !hidePastAct && !projectIds && !userEmail){
                showNone= true;
            }
            this.setState({
                showDateRangeChip:dateRange,
                showDateToggleChip:hidePastAct,
                showProjectChip:projectIds,
                showUserChip:userEmail,
                hidePastAct:hidePastAct,
                showNone:showNone
            });
        }
  }
  CheckPersonsInField = (prevPeople :IUserPersonaField[]|null,CurrPeople: IUserPersonaField[]|null) =>{
    let flag:boolean = false;
    var currPeopleEmail:string[] = CurrPeople?_.map(CurrPeople,'Email'):[];
    if(prevPeople){
        for (let people = 0; people < prevPeople.length; people++) {
            const prevPerson:IUserPersonaField = prevPeople[people];
            if(currPeopleEmail.indexOf(prevPerson.Email) === -1){
                flag = true;
            }
        }
    }
    return flag;
  }
  CheckProjectField = (prevProject :string[]|null,CurrProject: string[]|null) =>{
    let flag:boolean = false;
    if(prevProject){
        for (let Project = 0; Project < prevProject.length; Project++) {
            const prevPrjctId:string = prevProject[Project];
            if(CurrProject?.indexOf(prevPrjctId) === -1){
                flag = true;
  
            }
        }
    }
    return flag;
  }
  handleDelete = (filter:any) =>{
    if(filter === "All"){
        this.setState({
            showDateRangeChip:false,
            showDateToggleChip:false,
            showProjectChip:false,
            showUserChip:false,
            hidePastAct:false,
            showNone:true
        });
    }
    else if (filter === "Range") {
        this.setState({
            showDateRangeChip:false
        });
    }
    else if (filter === "hidePast") {
        this.setState({
            showDateToggleChip:false
        });
    }
    else if (filter === "User") {
        this.setState({
            showUserChip:false
        });
    }
    else if (filter === "Project") {
        this.setState({
            showProjectChip:false
        });
    }
    else{
        console.log("Unkown Filter Chip Deleted");
    }
    const {showDateRangeChip,showDateToggleChip,showProjectChip,showUserChip} = this.state;
    if(!showDateRangeChip && !showDateToggleChip && !showProjectChip && !showUserChip){
        this.setState({
            showNone:true
        }); 
    }
    this.props.handleDeleteFilter(filter);
  }
  getProjectTitle = ():string =>{
    const {FilterData,Projects} = this.props;
    let ProjectTitle:string = "",projectTileArr:string[] =[]; 
    if(FilterData.ProjectId && FilterData.ProjectId.length>0){
        for (let prjct = 0; prjct < FilterData.ProjectId.length; prjct++) {
            const projectId = FilterData.ProjectId[prjct];
            var filteredProjects:any = _.filter(Projects,{Id:projectId});
            if(filteredProjects){
               if(filteredProjects.length>0)
                projectTileArr.push(filteredProjects[0].Name);
            }
        }
    }
    ProjectTitle = projectTileArr.sort().join(", ");
    return ProjectTitle;
  }
  getrangeChipTitle = ():string =>{
    const {FilterData} = this.props;
    let rangeTitle:string = ""; 
    if(FilterData.FromDate && FilterData.ToDate){
        rangeTitle = "From Date: "+ formatActivityFilterDate(FilterData.FromDate) +" To Date: "+formatActivityFilterDate(FilterData.ToDate) 
    }    
    return rangeTitle;
  }
  public render() {
      const {showDateRangeChip,showDateToggleChip,showProjectChip,showUserChip,showNone} = this.state;
      const dateToggleLabel:string = "Hide Past";
      const {FilterData} = this.props;
        let userNameArr:any = FilterData.User? _.map(FilterData.User,'DisplayName'):[];
        let userChipTitle:string = userNameArr? userNameArr.join(", "):"";
        let projectChipTitle:string = this.getProjectTitle(); 
        let rangeChipTitle:string = this.getrangeChipTitle(); 
    return (
      <div style={{overflowY:"auto",width:"100%",fontSize:"12pt",display:"flex"}}>
        <div style={{fontSize: "12pt",  textAlign:"left",margin:"0px 15px"}}>
            Filters:
        </div>
        {showDateRangeChip && 
        (<Chip style={{backgroundColor:'#4f4e4d',marginRight:5,cursor:"Pointer"}} title={rangeChipTitle} label="Date Range" size="small" color="primary" data-filter ={"Range"} 
                onDelete={() =>{this.handleDelete("Range")}} />)}
        {showDateToggleChip && 
        (<Chip style={{backgroundColor:'#4f4e4d',marginRight:5,cursor:"Pointer"}}  title={"Hide Past Activities"} label={dateToggleLabel} size="small" color="primary" data-filter ={"hidePast"} 
                onDelete={() =>{this.handleDelete("hidePast")}} />)}
        {showProjectChip && 
        (<Chip style={{backgroundColor:'#4f4e4d',marginRight:5,cursor:"Pointer"}} title={projectChipTitle} label="Project" size="small" color="primary" data-filter ={"Project"} 
        onDelete={() =>{this.handleDelete("Project")}} />)}
        {showUserChip && 
        (<Chip style={{backgroundColor:'#4f4e4d',marginRight:5,cursor:"Pointer"}} title={userChipTitle} label="User" size="small" color="primary" data-filter ={"User"} 
        onDelete={() =>{this.handleDelete("User")}} />)}
        {!showNone && 
        (<Chip style={{backgroundColor:"#edbd11",marginRight:5,cursor:"Pointer",color:"black"}} label="Clear All FIlters" size="small" clickable color="primary" data-filter ={"All"} 
        onClick={() =>{this.handleDelete("All")}} />)}
        {showNone && <Chip style={{marginRight:5,cursor:"Pointer"}} label="None" size="small" color="default" data-filter ={"none"}/>}
      </div>
    );
  }
}
