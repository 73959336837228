import React, { ChangeEvent } from 'react';
import { IActivityGridState } from "./interfaces/IActivityGridState";
import ActivityGridService from "../../services/activity-grid-service";
import {DetailsList, ConstrainMode, CheckboxVisibility, IDetailsListStyles, IGroup, IDetailsRowStyles, DetailsRow,
     Selection, SelectionMode, IDetailsGroupRenderProps,IGroupDividerProps, IDetailsListProps} 
from 'office-ui-fabric-react/lib/DetailsList';
import {Icon} from 'office-ui-fabric-react';
import { FontIcon, ImageIcon } from 'office-ui-fabric-react/lib/Icon';
import { Redirect, Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { IActivityGridModel } from "./interfaces/IActivityGridModel";
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import { UserInfoContext } from '../../Main';
import AppConfig from '../../Constants';
import { groupAndSortGridItems, IGroupData } from './GroupGridItems';
import * as Utils from "../utils/Utils";
import OutlookButtonForGrid from '../Activity/OutLookEventSync/OutlookButtonForGrid';
import { IActivityShift } from '../Activity/interfaces/IActivityForm';
import { IActivitySync } from '../Activity/interfaces/IOutlookSync';
import { SyncService } from '../Activity/OutLookEventSync/SyncService';
import { v4 as uuidv4 } from 'uuid';


export interface IActivityGridProps
{
    activities: IActivityGridModel[];
    groups: IGroup[];
    sLAData: number[];
    isLoading: boolean;
    showAllActivities: boolean;
    showAllActivitiesLoading: boolean;
    errorMessage: string;
    ShiftTimmingsArr:IActivityShift[];
    ActivitySyncData: IActivitySync[];
    ActivitySyncIdArr: number[];
    handleActSyncSave:(actSyncArr:any[],action:string) =>void;

}
const iconClass = mergeStyles({
  fontSize: 30,
  height: 50,
  width: 50,
  margin: '0 25px',
});

const detailsListStyles:Partial<IDetailsListStyles> = {
    headerWrapper:{
        selectors: {
            '& .ms-DetailsHeader-cell:hover': {
                backgroundColor: 'white!important',
            },
        }
    },
}

export class ActivityGrid extends React.Component<IActivityGridProps,IActivityGridState>  {
    private _selection: Selection;
    constructor(props: IActivityGridProps){
        super(props);       

        this._selection = new Selection({
            onSelectionChanged: () => this._onItemSelectionChange()
        });          

        ActivityGrid.contextType = UserInfoContext;

        this.state={
            activities: [],
            groups: [],
            sLAData:[],
            isLoading: true,
            showAllActivities: false,
            showAllActivitiesLoading: false,
            selectedActivityIndex: -1,
            redirectURI: "",
            hideEditActBtn: true,
            hideEditExpBtn: true,
            errorMessage: "",
        };
    }
    public render(): React.ReactElement {    
        return (
            <div style={{width:"100%", textAlign:"center",overflow:"auto"}}>               
                {
                    this.state.activities.length > 0 ? 
                        <div style={{width:"90%",margin:"0 auto"}}>
                            <DetailsList
                                compact={true}
                                items={ this.state.activities}
                                columns= { this._getColumns() }
                                groups={ this.state.groups }
                                groupProps={{
                                    onRenderHeader: this._onRenderGroupHeader
                                }}
                                onRenderRow={this._onRenderRow}
                                constrainMode={ ConstrainMode.horizontalConstrained }
                                checkboxVisibility={CheckboxVisibility.always}
                                onShouldVirtualize={ () => false }
                                selection={this._selection} 
                                selectionMode={SelectionMode.none}  
                                selectionPreservedOnEmptyClick={false}
                                styles={detailsListStyles}                                
                            ></DetailsList>
                        </div>:
                        <span></span>
                }
                {
                    this.state.errorMessage !== "" ? <div style={{color: "red"}}>{this.state.errorMessage}</div> : ""
                }
                { this.state.redirectURI != "" ? <Redirect to={this.state.redirectURI} /> : "" } 
                  
            </div>
        );
    }   
    private getEditExpLink(GroupData:IGroupData):JSX.Element{
        if(GroupData && Object.keys(GroupData).length>0){
            if (GroupData.isEditable) {
                return (
                    <Link style={{fontSize:"12pt",paddingLeft:"20px"}} to={"/PlanExperiment?id=" + GroupData.ExpId} >
                        <FontIcon iconName="Edit" title="Edit Experiment" style={{color: "#fff"}}/>      
                    </Link> 
                );
            }
            else {
                return (
                    <Link style={{fontSize:"12pt",paddingLeft:"20px"}} to={"/PlanExperiment?id=" + GroupData.ExpId+"&view=1"} >
                        <FontIcon iconName="LockSolid" title="View-Only Experiment" style={{color: "#fff"}}/>      
                    </Link>
                );
            }
        }
        else{
            return <></>;
        }
    }
    private _onRenderGroupHeader: IDetailsGroupRenderProps['onRenderHeader'] = props => {
        // console.log(props);
        if (props) {
          return (            
            <div 
            onClick={this._onToggleCollapse(props)}
            style={{fontSize:"12pt", textAlign:"left", padding:"10px",backgroundColor:"#4f4e4d",color:"#fff", cursor:"pointer"}}>
                <FontIcon 
                    iconName={props.group!.isCollapsed ? "ChevronRightMed" : "ChevronDownMed"} 
                    title={props.group!.isCollapsed ? "Expand" : "Collapse"} 
                    style={{color: "#fff", paddingRight:"10px"}}/>      
                {`${props.group!.name}`} 
                {this.getEditExpLink(props.group?.data)}
            </div>
          );
        }
        return null;
    }
    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
          if (props.itemIndex % 2 === 0) {
            // Every other row renders with a different background color
            customStyles.root = { backgroundColor: "#ebe9e8" };
          }
    
          return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
    }
    _onToggleCollapse = (props: IGroupDividerProps) => {
        return () => {
          props!.onToggleCollapse!(props!.group!);
        };
    }
    _onItemSelectionChange = () => {
        let selIndices = this._selection.getSelectedIndices();        
        if(selIndices.length > 0){
            this.setState({
                selectedActivityIndex: this._selection.getSelectedIndices()[0],
                hideEditActBtn: selIndices.length==1 ? true : false
            });
        }
        else{
            this.setState({
                selectedActivityIndex: -1,
                hideEditActBtn: true,
                hideEditExpBtn: true                
            });
        }
    }
    _onAddActClick = () => {
        this.setState({
            redirectURI: "/PlanActivity"
        });
    }
    _onEditActClick = () => {
        let selIndex = this.state.selectedActivityIndex;                          
        this.setState({
            redirectURI: "/PlanActivity/" + selIndex.toString()
        });
    }
    _onAddExpClick = () => {
        this.setState({
            redirectURI: "/PlanExperiment"
        });
    }
    _onEditExpClick = () => {
    }
    _onActBtnClick = () => {
        let selIndex = this.state.selectedActivityIndex;                  
        this.setState({
            redirectURI: selIndex >= 0 ? "/PlanActivity/" + selIndex.toString() : "/PlanActivity"
        });
    }
    _onExpBtnClick = () => {  
        let selIndex = this.state.selectedActivityIndex;                  
        this.setState({
            redirectURI: selIndex >= 0 ? "/PlanExperiment/" + selIndex.toString() : "/PlanExperiment"
        });
    }    
    componentDidMount(){
        // this._getDataFromDB();
        const {activities,groups,sLAData,isLoading,showAllActivities,showAllActivitiesLoading,errorMessage} =this.props;
        this.setState({
            activities,
            groups,
            sLAData,
            isLoading,
            showAllActivities,
            showAllActivitiesLoading,
            errorMessage
        });
    }
    componentDidUpdate(prevProps:IActivityGridProps,prevState:IActivityGridState){
        const {activities,groups,sLAData,isLoading,showAllActivities,showAllActivitiesLoading,errorMessage} =this.props;
        if(prevProps.isLoading!== isLoading){
            this.setState({
                activities,
                groups,
                sLAData,
                isLoading,
                showAllActivities,
                showAllActivitiesLoading,
                errorMessage
            });
        }
        if(prevProps.activities.length!== activities.length){
            this.setState({
                activities,
                groups,
                sLAData,
                isLoading,
                showAllActivities,
                showAllActivitiesLoading,
                errorMessage
            });
        }
        if(prevProps.sLAData.length!== sLAData.length){
            this.setState({
                activities,
                groups,
                sLAData,
                isLoading,
                showAllActivities,
                showAllActivitiesLoading,
                errorMessage
            });
        }
        if(prevProps.errorMessage !== errorMessage){
            this.setState({
                activities,
                groups,
                sLAData,
                isLoading,
                showAllActivities,
                showAllActivitiesLoading,
                errorMessage
            });
        }
    } 
    _getEditLink = (item: IActivityGridModel): string=>{ 
        let editLink = "/PlanActivity?id=";
        if(item.LocationName){
            let locArr = item.LocationName?.split(",");
            if(locArr.indexOf(AppConfig.ActivityForm.officeSpaceUsageLocationName)!==-1){
                editLink = "/PlanOfficeSpace?id=";
            }
        }
        return editLink;       
    }
    checkIsPastRecord = (item:IActivityGridModel):boolean =>{
        let flag:boolean = true;
        let currActivityDate = moment(item.ActivityDate);
        let currDate = moment(new Date());
        let diffDates = currDate.diff(currActivityDate,'days');
        if(diffDates>0){
            flag = false;
        }
        return flag;
    }
    _getColumns = () => {        
        return [
            {
                key: "Edit",
                name: "Edit",
                fieldName: "ActivityId",
                minWidth: 25,
                maxWidth: 25,
                onRender: (item: IActivityGridModel) => {
                    let linkURL = this._getEditLink(item);
                return <div>
                            {this.checkIsPastRecord(item) && (
                            item.BookedByEmail?.toUpperCase()===this.context.userName.toUpperCase() 
                            || item.BookedForEmail?.toUpperCase()===this.context.userName.toUpperCase() 
                            || item.Owner?.toUpperCase()===this.context.userName.toUpperCase() 
                            || this.context.isAdmin)?(
                                <Link to={linkURL + item.ActivityId.toString()}>
                                    <FontIcon iconName="EditSolid12" title="Edit Activity" style={{color: "#4f4e4d"}}/>  
                                </Link>): 
                                (<Link to={linkURL + item.ActivityId.toString()+"&view=1"}>
                                    <FontIcon iconName="LockSolid" title="View-Only Activity" style={{color: "#4f4e4d"}}/>  
                                </Link>)}
                        </div>
                }
            },             
            {
                key: "ToggleButton",
                name: "",
                fieldName: "",
                minWidth: 80,
                maxWidth: 90,
                onRender: (item: IActivityGridModel) => {
                  return  <OutlookButtonForGrid handleActSyncSave={this.props.handleActSyncSave} Shifts = {this.props.ShiftTimmingsArr} item={item} syncData={_.filter(this.props.ActivitySyncData,{ActivityId:item.ActivityId})} isPushed ={this.props.ActivitySyncIdArr.indexOf(item.ActivityId)!==-1} />
                },
            },
            {
                key: "IsOverBooked",
                name: "",
                fieldName: "IsOverBooked",
                minWidth: 30,
                maxWidth: 30,
                onRender: (item: IActivityGridModel) => {
                    return item.IsOverBooked?
                    (
                        <div style={{ margin:"-8px 0px" }} >
                            <Icon
                                title ={"Over Booked"}
                                style={{fontSize:30, fontWeight:600, color: "red", margin:"-1px 0px" }}
                                iconName="StatusTriangleExclamation"
                                className="iconPadClasss"
                            />
                        </div>
                    ):"";
                }
            },
            {
                key: "ActivityName",
                name: "Activity",
                fieldName: "ActivityName",
                minWidth: 250,
                maxWidth: 280,
            },
            {
                key: "ActivityDate",
                name: "Date",
                fieldName: "ActivityDate",
                minWidth: 70,
                maxWidth: 80,
                onRender: (item: IActivityGridModel) => {
                    return <span>{moment.utc(item.ActivityDate).format("MM/DD/YYYY")}</span>;
                }
            },
            {
                key: "ShiftName",
                name: "Shift(s)",
                fieldName: "ShiftName",
                minWidth: 70,
                maxWidth: 80,
                onRender: (item: IActivityGridModel) => {
                    if(!item.ShiftName){
                        return <span></span>;
                    }
                    else{
                        let splitArr:any[] = item.ShiftShortName?.split(',');
                        let shiftChunks =  _.chunk(splitArr,2);
                        return (<div>
                            { shiftChunks?.map((shift,key)=>{return <div key={key}>{shift.join(", ") + (shiftChunks?.length !== key + 1? ",":"")}</div>})}
                            </div>
                        );
                    }
                }
            }, 
            // {
            //     key: "ShiftName",
            //     name: "Shift(s)",
            //     fieldName: "ShiftName",
            //     minWidth: 70,
            //     maxWidth: 80,
            //     onRender: (item: IActivityGridModel) => {
            //         if(!item.ShiftName){
            //             return <span></span>;
            //         }
            //         else{
            //             let shiftTimings = SyncService.getShiftTimingsArr( item.ActivityDate, item.SelectedShifts, this.props.ShiftTimmingsArr);
            //             let shiftArr = SyncService.clubShiftTimings(shiftTimings);
            //             return (<div>
            //                 { shiftArr?.map((shift,key)=>{return <div key={key}>{shift.STime +"-"+shift.ETime}</div>})}
            //                 </div>
            //             );
            //         }
            //     }
            // },
            {
                key: "LocationName",
                name: "Equipment(s)",
                fieldName: "LocationName",
                minWidth: 120,
                maxWidth: 150,
                onRender: (item: IActivityGridModel) => {
                    if(!item.LocationName){
                        return <span></span>;
                    }
                    else{
                        let uniqLocArr = item.LocationName.split(',');
                        return (<div>
                            { uniqLocArr.map((loc,key)=>{return <div key={key}>{loc}</div>})}
                            </div>
                        );
                    }
                }
            },
            /* {
                key: "EquipmentName",
                name: "Equipment(s)",
                fieldName: "EquipmentName",
                minWidth: 120,
                maxWidth: 150,
                onRender: (item: IActivityGridModel) => {
                    if(!item.EquipmentName){
                        return <span></span>;
                    }
                    else{
                        let uniqEquipArr = item.EquipmentName.split(',');
                        return (<div>
                            { uniqEquipArr.map((eq,key)=>{return <div key={key}>{eq}</div>})}
                            </div>
                        );
                    }
                }
                
            }, */
            {
                key: "ProjectName",
                name: "Project",
                fieldName: "ProjectName",
                minWidth: 110,
                maxWidth: 130
            },
            {
                key: "BookedForName",
                name: "Person",
                fieldName: "BookedForName",
                minWidth: 150,
                maxWidth: 180,
                onRender: (item: IActivityGridModel) => {
                    if(Utils.strEqualsCI(item.BookedByEmail,item.BookedForEmail)){
                    return <span>{item.BookedForName}</span>;
                    }
                    else{
                        let iconTitle:string ="Booked By: "+item.BookedByName +"\nBooked For: " +item.BookedForName;
                        return (<div style={{display:"flex"}} title={iconTitle} >
                            <FontIcon iconName="PeopleAlert" style={{color: "black",fontSize:16,fontWeight:400}}/>
                            <div style={{marginLeft:3}}>{item.BookedForName}</div>
                            </div>
                        );
                    }
                  }
            }
        ];
    }
}    