import _ from "lodash";
import { reject } from "lodash";
import AppConfig from "../../../Constants";
import ActivitySyncService from "../../../services/activity-sync-service";
import CacheManager from "../../../services/CacheManager";
import { createBatchRequest, createCalendarEvent, getAuthenticatedClient } from "../../../services/graph-service";
import { formatActivityDate } from "../ActivityService";
import { IActivityShift } from "../interfaces/IActivityForm";
import { IActivitySync, IEditOutlootEvent, IOutlootEvent, IShiftTimings } from "../interfaces/IOutlookSync";

export const SyncService  = {

    clubShiftTimings: (shiftTimings: IShiftTimings[]):IShiftTimings[] =>{
        let shiftTimingsArr:IShiftTimings[] = [] as IShiftTimings[];
        for (let index = 0; index < shiftTimings.length; index++) {
            const eachShift = shiftTimings[index]; 
            let consecutiveShiftIndex = SyncService.isConsecutiveShifts(shiftTimings,eachShift);
            if( consecutiveShiftIndex!==-1){
                //advance end Time
                eachShift.ShiftOrder = shiftTimings[consecutiveShiftIndex].ShiftOrder;
                eachShift.ETime = shiftTimings[consecutiveShiftIndex].ETime;
                eachShift.EndTime = shiftTimings[consecutiveShiftIndex].EndTime;
                shiftTimingsArr.push(eachShift);
                shiftTimings.splice(consecutiveShiftIndex,1);
                index--;
            }else{
                shiftTimingsArr.push(eachShift);
            }
            // if(eachShift.ShiftOrder === "4"){
            //     break;
            // } 
        }
        return _.uniqBy(shiftTimingsArr,'ShiftOrder');
    },
    isConsecutiveShifts: (shiftTimingsArr:IShiftTimings[], compareShiftTime:IShiftTimings):number =>{
        for (let index = 0; index < shiftTimingsArr.length; index++) {
            const eachShiftTime = shiftTimingsArr[index];
            if(parseInt(compareShiftTime.ShiftOrder) + 1 === parseInt(eachShiftTime.ShiftOrder)){
                //consecutive
                return index;
            } 
        }
        return -1;
    },
    getShiftTimingsArr:(activityDate:any, selectedShiftIds: number[],ShiftTimings:IActivityShift[]): IShiftTimings[] => {
        let shiftTimingsArr:IShiftTimings[] = [] as IShiftTimings[];
        for (let index = 0; index < selectedShiftIds.length; index++) {
            const selectedLocId = selectedShiftIds[index];
            let filterShiftsByIdArr:IActivityShift[] = _.filter(ShiftTimings,{Id:selectedLocId,StatusId:AppConfig.ActivityForm.ActiveStatusId});
            if (filterShiftsByIdArr && filterShiftsByIdArr.length>0) {
                let fiteredShift:IActivityShift =  filterShiftsByIdArr[0];
                let actDateStr:string = "";
                if(typeof activityDate.getMonth === 'function')
                {
                    actDateStr = formatActivityDate(activityDate);
                }else{
                    actDateStr = activityDate;
                }
                let dateString:string = actDateStr.split("T")[0];
                let shiftTimings:IShiftTimings = {} as IShiftTimings;
                let startString:string = SyncService.getTimeString(fiteredShift,"Start"); 
                let endString:string = SyncService.getTimeString(fiteredShift,"End"); 
                let shiftStartTime:string = startString + ":00";
                let shiftEndTime:string = endString + ":00";
                shiftTimings.STime = startString;
                shiftTimings.ETime = endString;
                shiftTimings.ShiftOrder = fiteredShift.ShiftOrder;//SyncService.getShiftOrder(fiteredShift.Name);
                shiftTimings.StartTime = dateString + "T" + shiftStartTime;
                shiftTimings.EndTime = dateString + "T" + shiftEndTime;
                shiftTimingsArr.push(shiftTimings);
            }            
        }
        return shiftTimingsArr;
    },
    getTimeDiplayString: (time:number):string =>{
        let timeStr:string ="";
        timeStr =  time<10?"0"+time:time.toString();
        return timeStr;
    },
    getShiftOrder: (shiftName:string):string =>{
        let shiftOrder:string ="";
        shiftOrder =  shiftName.split(AppConfig.OutLook.ShiftOrderNameString)[1];
        return shiftOrder;
    },
    getTimeString:(shiftdata: IActivityShift, selection: string): string =>{
        let timeStr:string = "00:00";
        if (selection === "Start") {
            timeStr =  SyncService.getTimeDiplayString(shiftdata.StartHour) + ":" +SyncService.getTimeDiplayString(shiftdata.StartMin);
        }else{
            timeStr =  SyncService.getTimeDiplayString(shiftdata.EndHour) + ":" + SyncService.getTimeDiplayString(shiftdata.EndMin);
        }
        return timeStr;
    },
    getGrphClientAccessToken: ():string =>{
        const cacheAccountInfo:any = CacheManager.getCacheItem('accountInfo');
        let accountInfo:any = {},
            accessToken = '';
        try {
            accountInfo = JSON.parse(cacheAccountInfo.value);
            accessToken = accountInfo.jwtAccessToken
                ? accountInfo.jwtAccessToken
                : getAuthenticatedClient();
        } catch (err) {
            console.log('account Info error', err);
        }
        return accessToken;
    },
    getActivitySyncForUser: (activityId:number, currUser:string)=>{
        var actSyncObj = {ActivityId:activityId,EventSyncedBy:currUser};
        return new Promise((resolve, reject) => {
            ActivitySyncService.getActivitySyncForUser(actSyncObj)
                .then(actSyncRecord => resolve(actSyncRecord))
                .catch(error => {
                    reject(error);
                });
        });
    }, 
    getGridActivitySyncForUser: (activityIdArr:number[], currUser:string)=>{
        var actSyncObj = {ActivityIds:activityIdArr,EventSyncedBy:currUser};
        return new Promise((resolve, reject) => {
            ActivitySyncService.getGridActivitySyncForUser(actSyncObj)
                .then(actSyncRecord => resolve(actSyncRecord))
                .catch(error => {
                    reject(error);
                });
        });
    }, 
    getExpActivitySyncForUser: (activityId:number, currUser:string)=>{
        var actSyncObj = {ExperimentId:activityId,EventSyncedBy:currUser};
        return new Promise((resolve, reject) => {
            ActivitySyncService.getExpActivitySyncForUser(actSyncObj)
                .then(actSyncRecord => resolve(actSyncRecord))
                .catch(error => {
                    reject(error);
                });
        });
    },
    createActivitySyncRecord: (activitySynObj: IActivitySync) =>{
        return new Promise((resolve, reject) => {
            ActivitySyncService.createActivitySyncRecord(activitySynObj)
                .then(actSyncRecord => resolve(actSyncRecord))
                .catch(error => {
                    reject(error);
                });
        });
    },  
    createBatchActivitySyncRecord: (activitySynArr: IActivitySync[],currUserEmail:string) =>{
        return new Promise((resolve, reject) => {
            ActivitySyncService.createBatchActivitySyncRecord(activitySynArr,currUserEmail)
                .then(actSyncRecord => resolve(actSyncRecord))
                .catch(error => {
                    reject(error);
                });
        });
    },
    purgeActivitySyncRecord:(ActId:number, currUser:string) =>{
        return new Promise((resolve, reject) => {
            ActivitySyncService.deleteActivitySyncRecordById(ActId, currUser)
                .then(actSyncRecord => resolve(actSyncRecord))
                .catch(error => {
                    reject(error);
                });
        });
    },   
    purgeMultipleExpActivitySyncRecord:(ExpId:number, currUser:string, isRemoveAll:boolean) =>{
        return new Promise((resolve, reject) => {
            ActivitySyncService.deleteActivitySyncRecordByExpId(ExpId, currUser,isRemoveAll)
                .then(actSyncRecord => resolve(actSyncRecord))
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateActivitySyncRecord:(activitySynObj: IActivitySync) =>{
        return new Promise((resolve, reject) => {
            ActivitySyncService.updateActivitySyncRecord(activitySynObj)
                .then(actSyncRecord => resolve(actSyncRecord))
                .catch(error => {
                    reject(error);
                });
        });
    },
    CreateIndvidualOutlookEvent:(calendarDetails: IOutlootEvent[]) => {
        let accessToken:string = SyncService.getGrphClientAccessToken();
        return new Promise((resolve,reject) => {
            createCalendarEvent(calendarDetails, accessToken)
            .then(calendarResponse => {
                resolve(calendarResponse);
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    formBatchCalEventRequest: (calendarDetails:IOutlootEvent[]) => {
        let batchRequest:any = {};
        batchRequest.requests = [];
        for (let index = 0; index < calendarDetails.length; index++) {
            let _req:any = {}; 
            const event = calendarDetails[index];
            _req.id = index + 1;
            _req.method = "POST";
            _req.url = "/me/events";
            _req.headers = {};
            _req.headers["Content-Type"] = "application/json"; 
            _req.body = event;
            batchRequest.requests.push(_req);
        }
        return batchRequest;
    },
    CreateOutlookEvent:(calendarDetails: IOutlootEvent[]):any => {
        let accessToken:string = SyncService.getGrphClientAccessToken();
        let batchRequest:any = SyncService.formBatchCalEventRequest(calendarDetails);  
        return new Promise((resolve,reject) => {
            createBatchRequest(batchRequest, accessToken)
            .then(calendarResponse => {
                resolve(calendarResponse);
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    formBatchDeleteCalEventRequest: (calendarDetails:IActivitySync[]) => {
        let batchRequest:any = {};
        batchRequest.requests = [];
        for (let index = 0; index < calendarDetails.length; index++) {
            let _req:any = {}; 
            const event = calendarDetails[index];
            _req.id = index + 1;
            _req.method = "DELETE";
            _req.url = `/me/events/${event.EventId}`;
            _req.headers = {};
            _req.headers["Content-Type"] = "application/json"; 
            _req.body = event;
            batchRequest.requests.push(_req);
        }
        return batchRequest;
    },
    DeleteOutlookEvent: (calendarDetails: IActivitySync[]) => {
        let accessToken:string = SyncService.getGrphClientAccessToken();
        let batchRequest:any = SyncService.formBatchDeleteCalEventRequest(calendarDetails);  
        return new Promise((resolve,reject) => {
            createBatchRequest(batchRequest, accessToken)
            .then(calendarResponse => {
                resolve(calendarResponse);
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    formBatchUpdateCalEventRequest: (calendarDetails:IEditOutlootEvent[]) => {
        let batchRequest:any = {};
        batchRequest.requests = [];
        for (let index = 0; index < calendarDetails.length; index++) {
            let _req:any = {}; 
            const event = calendarDetails[index];
            _req.id = index + 1;
            _req.method = "PATCH";
            _req.url = `/me/events/${event.EventId}`;
            _req.headers = {};
            _req.headers["Content-Type"] = "application/json"; 
            _req.body = event.Body;
            batchRequest.requests.push(_req);
        }
        return batchRequest;
    },
    UpdateOutlookEvent: (calendarDetails:IEditOutlootEvent[]) => {
        let accessToken:string = SyncService.getGrphClientAccessToken();
        let batchRequest:any = SyncService.formBatchUpdateCalEventRequest(calendarDetails);  
        return new Promise((resolve,reject) => {
            createBatchRequest(batchRequest, accessToken)
            .then((calendarResponse:any) => {
                resolve(calendarResponse);
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    }
}