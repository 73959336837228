import {  IPermissions, IUserDetail } from "../Main/IMainApp";
import _, { toUpper } from "lodash";
import AppConfig from "../Constants";


export const RoleVerbs = {
    Create:"Create",
    Edit:"Edit",
    Clone:"Clone",
    Delete:"Delete",
    DeletePast:"DeletePast",
    EditPast:"EditPast"
}
export const RolePermission = {
    CloneExpActivity:"CloneExpActivity", 
    CloneActivity:"CloneActivity", 
    CreateExpActivity:"CreateExpActivity",
    DeleteActivity:"DeleteActivity",
    DeleteExpActivity:"DeleteExpActivity",
    DeleteExperiment:"DeleteExperiment",
    ChangePastRecord:"ChangePastRecord",
    EditActivity:"EditActivity",  
    EditExpActivity:"EditExpActivity",
    EditExperiment:"EditExperiment", 
    MasterACM:"MasterACM",  
    MasterEquipment:"MasterEquipment",
    MasterEquipmentType:"MasterEquipmentType",
    MasterLocation:"MasterLocation",
    MasterProject:"MasterProject", 
    MasterShift:"MasterShift"
}
const getControlPermissionsWithVerb = (userType:"ExpOwner"|"Actowner",verb:string):string=>{
    var controlKey:string = "";
    switch (verb) {
        case RoleVerbs.Edit:
            return userType ==="ExpOwner"?RolePermission.EditExpActivity:RolePermission.EditActivity;
        case RoleVerbs.Clone:
            return userType ==="ExpOwner"?RolePermission.CloneExpActivity:RolePermission.CloneActivity;
        case RoleVerbs.Delete:
            return userType ==="ExpOwner"?RolePermission.DeleteExpActivity:RolePermission.DeleteActivity;
        case RoleVerbs.DeletePast:
            return userType ==="ExpOwner"?RolePermission.ChangePastRecord:RolePermission.ChangePastRecord;
        case RoleVerbs.EditPast:
            return userType ==="ExpOwner"?RolePermission.ChangePastRecord:RolePermission.ChangePastRecord;
        default:
            return "";
    }
}
const getControlPermissionsWithVerbAdmin = (verb:string):string=>{
    switch (verb) {
        case RoleVerbs.Edit:
            return RolePermission.EditActivity;
        case RoleVerbs.Clone:
            return RolePermission.CloneActivity;
        case RoleVerbs.Delete:
            return RolePermission.DeleteActivity;
        case RoleVerbs.DeletePast:
            return RolePermission.ChangePastRecord;
        case RoleVerbs.EditPast:
            return RolePermission.ChangePastRecord;
        default:
            return "";
    }
}
export const checkUserCanChangePast = (UserInfo:IUserDetail) :boolean =>{
    try {
        let sysRolePermissions:IPermissions[] = UserInfo.SysPermissions;
        let _permissions:IPermissions[] = _.filter(sysRolePermissions,{RoleId:AppConfig.Roles.SysAmdinRoleId});
        if(_permissions && _permissions.length>0){
            if(_permissions[0].RolePermissions.indexOf(RolePermission.ChangePastRecord)!== -1){
                return true;
            };
        } 
    } catch (error) {
        console.log(error);
    }
    return false; 
}
export const showActControl = (UserInfo:IUserDetail,currRole:"ExpOwner"|"Actowner"|"Admin"|"None",controlVerbKey:string):boolean =>{
    var showActControl:boolean = false;
    let sysRolePermissions:IPermissions[] = UserInfo.SysPermissions;
    if(currRole ==="Admin"){
        //Admin permissions need to applied
        let _permissions:IPermissions[] = _.filter(sysRolePermissions,{RoleId:AppConfig.Roles.SysAmdinRoleId});
        if(_permissions && _permissions.length>0){
            if(_permissions[0].RolePermissions.indexOf(getControlPermissionsWithVerbAdmin(controlVerbKey))!== -1){
                return true;
            };
        }            
    }
    else{
        if(currRole === "ExpOwner"){
            //experiment owner permissions need to applied 
            let _permissions:IPermissions[] = _.filter(sysRolePermissions,{RoleId:AppConfig.Roles.ActivityRoleId});
            if(_permissions && _permissions.length>0){
                if(_permissions[0].RolePermissions.indexOf(getControlPermissionsWithVerbAdmin(controlVerbKey))!== -1){
                    return true;
                };
            }
        }
        if(currRole === "Actowner"){
            //act person  permissions need to applied
            let _permissions:IPermissions[] = _.filter(sysRolePermissions,{RoleId:AppConfig.Roles.ActivityRoleId});
            if(_permissions && _permissions.length>0){
                if(_permissions[0].RolePermissions.indexOf(getControlPermissionsWithVerbAdmin(controlVerbKey))!== -1){
                    return true;
                };
            }
        }
    }
    return showActControl;
}
export const DisableExpControl = (UserInfo:IUserDetail,userRole:"ExpOwner"|"Admin"|"Actowner"|"None",controlKey:string):boolean =>{
    let sysRolePermissions:IPermissions[] = UserInfo.SysPermissions;
    if(UserInfo.isAdmin){
        let _permissions:IPermissions[] = _.filter(sysRolePermissions,{RoleId:AppConfig.Roles.SysAmdinRoleId});
        if(_permissions && _permissions.length>0){
            if(_permissions[0].RolePermissions.indexOf(controlKey) === -1){
                return true;
            }
        }       
    }
    if(userRole ==="ExpOwner"){
        //experiment owner / act person  permissions need to applied
        let _permissions:IPermissions[] = _.filter(sysRolePermissions,{RoleId:AppConfig.Roles.ExpRoleId});
        if(_permissions && _permissions.length>0){
            if(_permissions[0].RolePermissions.indexOf(controlKey) === -1){
                return true;
            }
        }
    }
    if(userRole ==="Actowner"){
        //experiment owner / act person  permissions need to applied
        let _permissions:IPermissions[] = _.filter(sysRolePermissions,{RoleId:AppConfig.Roles.ActivityRoleId});
        if(_permissions && _permissions.length>0){
            if(_permissions[0].RolePermissions.indexOf(controlKey) === -1){
                return true;
            }
        }
    }
    return false;
}
export const DisableExpActCloneControl = (UserInfo:IUserDetail,userRole:"ExpOwner"|"Admin"|"Actowner"|"None",DisableClone:boolean):boolean =>{
    // var disableExpControl:boolean = false;
    let sysRolePermissions:IPermissions[] = UserInfo.SysPermissions;
    if(UserInfo.isAdmin){
        let _permissions:IPermissions[] = _.filter(sysRolePermissions,{RoleId:AppConfig.Roles.SysAmdinRoleId});
        if(_permissions && _permissions.length>0){
            if(_permissions[0].RolePermissions.indexOf(RolePermission.CloneExpActivity) !== -1){
                return false;
            }
        }  
    }
    else if(userRole === "ExpOwner"){
        let _permissions:IPermissions[] = _.filter(sysRolePermissions,{RoleId:AppConfig.Roles.ExpRoleId});
        if(_permissions && _permissions.length>0){
            if(_permissions[0].RolePermissions.indexOf(RolePermission.CloneExpActivity) === -1){
                return true;
            }
        }
    }
    else{
        // act person permissions need to applied
        if(DisableClone){
            return true;
        }
        else{
            let _permissions:IPermissions[] = _.filter(sysRolePermissions,{RoleId:AppConfig.Roles.ActivityRoleId});
            if(_permissions && _permissions.length>0){
                if(_permissions[0].RolePermissions.indexOf(RolePermission.CloneExpActivity) === -1){
                    return true;
                }
            }
        }
    }
    return false;
}
export const DisableExpActDeleteControl = (UserInfo:IUserDetail,isOwner:boolean,DisableDelete:boolean):boolean =>{
    let sysRolePermissions:IPermissions[] = UserInfo.SysPermissions;
    var _permissions:IPermissions[] = [];
    if(UserInfo.isAdmin){
           //Admin permissions need to applied
           let _permissions:IPermissions[] = _.filter(sysRolePermissions,{RoleId:AppConfig.Roles.SysAmdinRoleId});
           if(_permissions && _permissions.length>0){
               if(_permissions[0].RolePermissions.indexOf(RolePermission.DeleteExpActivity)!== -1){
                   return false;
               };
           }  
    }
    else{        
       if(isOwner){
            _permissions = _.filter(sysRolePermissions,{RoleId:AppConfig.Roles.ExpRoleId});
            if(_permissions && _permissions.length>0){
                if(_permissions[0].RolePermissions.indexOf(RolePermission.DeleteExpActivity) === -1){
                    return true;
                }
            }
            if(DisableDelete){
                //not past and user not in Booked by/For  
                return true;
            }
        }else{
            //act owner 
            _permissions = _.filter(sysRolePermissions,{RoleId:AppConfig.Roles.ActivityRoleId});
            console.log(_permissions);
            if(_permissions && _permissions.length>0){
                if(_permissions[0].RolePermissions.indexOf(RolePermission.DeleteExpActivity) === -1){
                    return  true;
                } 
            }
            if(DisableDelete){
                //not past and user not in Booked by/For  
                return true;
            }
          
        }       
    }
    return false;
}
