import * as React from 'react';
import PropTypes from 'prop-types';
import {TextField, ITextFieldStyles,ITextFieldStyleProps} from 'office-ui-fabric-react/lib/TextField';
import {Label} from 'office-ui-fabric-react/lib/Label';
import {Toggle,IToggleStyles,IToggleStyleProps} from 'office-ui-fabric-react/lib/Toggle';
import BookedForEmail from '../Common/BookedForEmail';
import { IPersonVisitorProps, IPersonVisitorState } from './interfaces/IPersonVisitor';
import { IUserPersonaField, IActivity, IActivityErrors } from './interfaces/IActivityForm';
import { Dropdown, IDropdownOption,IDropdownStyles } from 'office-ui-fabric-react/lib/Dropdown';

const txtFieldClass:Partial<ITextFieldStyleProps> & Partial<ITextFieldStyles> = {
    wrapper: {
        width: '100%',
        display: 'block',
        selectors:{
            'disabled':{
                color:'black'
            }
        }
    },
    root: {
        width: '100%',
    },
    subComponentStyles:{
        label:{
            color:'black!important'
        }
    }
};
const toggleFieldClass: Partial<IToggleStyles> = {
    container: {
        marginTop: '4px',
    }
};
const dropdownStyles: Partial<IDropdownStyles> = { 
    dropdown :{
        selectors:{
            'disabled':{
                border:'1px solid black',
            }
        }
    },
    dropdownOptionText:{ color: 'black!important'},
    title :{ color: 'black!important'},
    label:{
        color:'black!important'
    }
};

export default class PersonVisitor extends React.Component<IPersonVisitorProps,IPersonVisitorState> {
    constructor(props:IPersonVisitorProps) {
        super(props);
        this.state = {
            showVisitorField:false,
            errorMsgs:{},
            visitorEmail:'',
            selectedFor:[]
        };
    }
    componentDidMount() {
        const {showVisitorField,errorMsgs,visitorEmail,selectedFor} = this.props;
        this.setState({
            showVisitorField,
            visitorEmail,
            errorMsgs,
            selectedFor
        });
    }
    componentDidUpdate(prevProps:IPersonVisitorProps, prevState:IPersonVisitorState) {
        const {errorMsgs,selectedFor,visitorEmail,showVisitorField} = this.props;
        if(visitorEmail !== prevProps.visitorEmail){
            this.setState({
                visitorEmail,
                showVisitorField
            });
        } if(showVisitorField !== prevProps.showVisitorField){
            this.setState({
                showVisitorField,
                visitorEmail
            });
        }if(selectedFor.length !== prevProps.selectedFor.length){
            this.setState({
                selectedFor
            });
        }
        else {
            if (selectedFor[0]?.Email !== prevProps.selectedFor[0]?.Email) {
                this.setState({
                    selectedFor
                });
            }
        }
        if(errorMsgs.visitorEmail !== prevProps.errorMsgs.visitorEmail){
            this.setState({
                errorMsgs
            });
        }
        if(errorMsgs.bookedForEmail !== prevProps.errorMsgs.bookedForEmail){
            this.setState({
                errorMsgs
            });
        }
        if(errorMsgs.bookedForEmail !== prevProps.errorMsgs.bookedForEmail){
            this.setState({
                errorMsgs
            });
        }
    }
  
    handleToggleChange= (ev:any) =>{
        let val:string = ev.target.value;
        let eventObj:any = {
            target:{
                value:val,
                name:'showVisitorField'
            }
        }
        const {showVisitorField}:any = this.state;
        eventObj.target.value = !showVisitorField;
        this.setState({
            visitorEmail:'',
            showVisitorField:!showVisitorField
        });
        this.props.handleOnChange(eventObj);
    }
    handleOnChange = (ev:any) => {
        let val:string = ev.target.value,
        fieldName:string = ev.target.name;
        let eventObj:any = {
            target:{
                value:val,
                name:fieldName
            }
        }
        switch (fieldName) {           
            case "visitorEmail":
                this.setState({
                    visitorEmail:val
                });                
                break;       
            default:
                break;
        };
        this.props.handleOnChange(eventObj);
    }
    updateBookedForEmail = (items:IUserPersonaField[], property:string) => {
        let eventObj = {
            target:{
                value:items,
                name:property,
                selectedFor:items
            }
        }
        this.setState({
            selectedFor: items 
        });
        this.props.handleOnChange(eventObj);
    }
    renderErrorMsgForField(msg:string,key:any){
        return(
             <div role="alert" key={key}>
                <p className="ms-TextField-errorMessage alertMsg">
                    <span data-automation-id="error-message">{msg}</span>
                </p>
            </div>
        );
    }
    render() {
        const {showVisitorField,errorMsgs,visitorEmail,selectedFor} = this.state;
        const {viewForm} = this.props;
        return (
            <div style={{display:"flex",alignItems:"center"}} >
                <div style={{width:"66%"}}>
                       <div>
                           {showVisitorField ? (
                                <>
                                    <TextField
                                        disabled={viewForm}
                                        label="Visitor Email"
                                        required
                                        name="visitorEmail"
                                        placeholder="Visitor Email"
                                        value={visitorEmail}
                                        errorMessage={errorMsgs.visitorEmail}
                                        onChange={this.handleOnChange}
                                        styles={txtFieldClass}
                                    />
                                </>
                            ) : (
                                <>
                                    <Label className={'requiredLabel'}>
                                        Person Name
                                    </Label>
                                    <div style={{backgroundColor: "white"}}>
                                        <BookedForEmail
                                            viewForm={viewForm}
                                            required={true}
                                            description={'Toggle Vistor field to if person is a visitor'}
                                            placeholderTxt={'Person Name'}
                                            property={'bookedForEmail'}
                                            updatePeoplePickerChange={this.updateBookedForEmail}
                                            people={selectedFor}
                                        />
                                    </div>
                                    {errorMsgs.bookedForEmail?this.renderErrorMsgForField(errorMsgs.bookedForEmail,'bookedForEmailError'):''}
                                </>
                            )}
                        </div>
                   </div>
                   <div style={{width:"3%"}}></div>
                   <div style={{width:"30%"}}>
                        <Toggle
                            disabled={viewForm}
                            label="Visitor?"
                            onText="Yes"
                            offText="No"
                            checked={showVisitorField}
                            styles={toggleFieldClass}
                            onChange={this.handleToggleChange}
                        />
               </div>
            </div>
        );
    }
}