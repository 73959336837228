import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/airbnb.css';
import './FlatDatePickr.css';
import {IconButton} from 'office-ui-fabric-react';
import {Label} from 'office-ui-fabric-react/lib/Label';
import AppConfig from '../../Constants';
import { v4 as uuidv4 } from 'uuid';
const moment = require('moment');

class FLatDatePickr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: null,
            viewForm:false,
            isPast:false,
            MaxDate:"02/28/2021",
            key:uuidv4()
        };
        this.handleDateSelect = this.handleDateSelect.bind(this);
    }

    componentDidMount() {
        const {dateInput,viewForm,isPast,NoCurrDateOnCancel,maxDate} = this.props;
        this.setState({
            date: dateInput,
            viewForm:viewForm,
            MaxDate:maxDate,
            isPast:isPast
        });
    }
    componentDidUpdate(prevProps, prevState) {
        const {dateInput,viewForm,isPast,maxDate} = this.props;
        if (prevProps.dateInput !== dateInput) {
            this.setState({
                date: dateInput,
                viewForm,
                MaxDate:maxDate,
                key:uuidv4()
            });
        }
        if (prevProps.viewForm !== viewForm) {
            this.setState({
                date: dateInput,
                MaxDate:maxDate,
                viewForm,
                key:uuidv4()
            });
        }
        if (prevProps.isPast !== isPast) {
            this.setState({
                date: dateInput,
                MaxDate:maxDate,
                viewForm,
                isPast,
                key:uuidv4()
            });
        }
        if (prevProps.maxDate !== maxDate) {
            this.setState({
                date: dateInput,
                MaxDate:maxDate,
                viewForm,
                isPast,
                key:uuidv4()
            });
        }
    }

    handleDateSelect(dateTime) {
        console.log(dateTime);
        if (dateTime.length) {
            this.setState({
                date: dateTime,
            });
            this.props.updateFunc(dateTime[0]);
        } else {
            var today = new Date();
            if(this.props.NoCurrDateOnCancel){
                today = null;
            }
            this.setState({
                date: today,
            });
            this.props.updateFunc(today);
        }
    }
    CheckIfTodayIsPastMinDate (date){
        var minDate = moment(AppConfig.ActivityForm.MinDate),
        currDate = moment(date);
        let diffDates = currDate.diff(minDate,'days');
        if( diffDates>0){
            return true;
        }
        return false;
    }
    render() {
        const {date, viewForm,isPast,MaxDate} = this.state;
        const {labelHead, showNonFormLabel, required,placeholderTxt,isCalendar} = this.props;
        const btnIcon = {iconName: 'Clear'}; 
        let minDateFlag = isCalendar?true:this.CheckIfTodayIsPastMinDate(date);
        var dateOptions = {
            mode: "single",
            wrap: true,
            minDate: minDateFlag?'today':AppConfig.ActivityForm.MinDate,
            enableTime: false,
            dateFormat: 'm/d/Y',
            maxDate: MaxDate

        };     
        var dateOptionwithoutMindate = {
            mode: "single",
            wrap: true,
            minDate: minDateFlag?'today':AppConfig.ActivityForm.MinDate,
            enableTime: false,
            dateFormat: 'm/d/Y',
        }; 
        var dateOptionwithoutMindatePast = {
            mode: "single",
            wrap: true,
            enableTime: false,
            dateFormat: 'm/d/Y',
            maxDate: MaxDate
        };  
        var currOptions = dateOptions;
        if(isPast){
            currOptions = dateOptionwithoutMindatePast;
        }
        if(viewForm){
            currOptions = dateOptionwithoutMindate;
        }
        return (
            <div className={'flatPickerDiv'}>
                {!showNonFormLabel && labelHead && (
                    <Label className={required && 'requiredLabel'}>
                        {labelHead}
                    </Label>
                )}
                {showNonFormLabel && labelHead && (
                    <label className={required && 'requiredLabel'}>
                        {labelHead}
                    </label>
                )}
                <Flatpickr
                    key={uuidv4()+labelHead}
                    value={date}
                    options={currOptions}
                    className={viewForm?'flatPickerClass viewFormDisabled':'flatPickerClass'}
                    onClose={this.handleDateSelect}>
                    <input disabled={viewForm}
                        key={uuidv4()+labelHead}
                        style={{border:0}}
                        type="text"
                        data-input
                        placeholder={placeholderTxt?placeholderTxt:"Select Date.."}
                        title={labelHead}
                    />
                    {viewForm?'':(
                        <IconButton
                            iconProps={btnIcon}
                            data-clear
                            disabled={viewForm}
                            title="Clear"
                            ariaLabel="clear"
                            onClick={this.handleDateSelect}
                        />
                    )}
                </Flatpickr>
            </div>
        );
    }
}

FLatDatePickr.propTypes = {
    labelHead: PropTypes.string.isRequired,
    updateFunc: PropTypes.func.isRequired,
    placeholderTxt:PropTypes.string,
    dateInput: PropTypes.any,
    required: PropTypes.bool,
    NoCurrDateOnCancel:PropTypes.bool,
    isPast:PropTypes.bool,
    viewForm:PropTypes.bool,
    maxDate:PropTypes.string,
    minDate:PropTypes.string,
    isCalendar:PropTypes.bool,
    showNonFormLabel: PropTypes.bool,
};

export default FLatDatePickr;
