
import AppConfig from '../Constants';
import { axiosGetCall, axiosPostCall, axiosPutCall } from './AxiosService';

function procesSystemRoles(newRole,creationFlag) {
    let jsonObj = {};
    try {
        if(!creationFlag){
            jsonObj.Id = newRole.Id;
        }
        jsonObj.Name = newRole.Name;
        jsonObj.Description = newRole.Description;
    } catch (error) {
        console.log(error);
        jsonObj = false;
    }
    return jsonObj;
}
function processHomePageConfig(newHomePageConfig,creationFlag) {
    let jsonObj = {};
    try {
        if(!creationFlag){
            jsonObj.Id = newHomePageConfig.Id;
        }
        jsonObj.LinkURL=newHomePageConfig.LinkURL;
        jsonObj.Title=newHomePageConfig.Title;
        jsonObj.Order=newHomePageConfig.Order;
        jsonObj.SectionName=newHomePageConfig.SectionName;
        jsonObj.Icon=newHomePageConfig.Icon;
        jsonObj.ToolTip=newHomePageConfig.ToolTip;
        jsonObj.isExternalLink=newHomePageConfig.isExternalLink;
        jsonObj.Description=newHomePageConfig.Description;
        jsonObj.Status = newHomePageConfig.StatusId;
    } catch (error) {
        console.log(error);
        jsonObj = false;
    }
    return jsonObj;
}
function processDelete(deleteObj) {
    let jsonObj = {};
    try {
        jsonObj.Id = deleteObj.Id;
        jsonObj.RecordType = deleteObj.RecordType;
    } catch (error) {
        console.log(error);
        jsonObj = false;
    }
    return jsonObj;
}
function procesSystemRolePermissions(rolePermission,creationFlag) {
    let jsonObj = {};
    try {
        if(!creationFlag){
            jsonObj.Id = rolePermission.Id;
        }
        jsonObj.RoleId = rolePermission.RoleId;
        jsonObj.PermissionNames = rolePermission.PermissionNames;
        jsonObj.Status = rolePermission.Status?AppConfig.ActivityForm.ActiveStatusId:AppConfig.ActivityForm.InactiveStatusId;
    } catch (error) {
        console.log(error);
        jsonObj = false;
    }
    return jsonObj;
}
function procesSystemUserRoles(rolePermission,creationFlag) {
    let jsonObj = {};
    try {
        jsonObj.Name = rolePermission.User.DisplayName;
        jsonObj.EmailId = rolePermission.User.Email;
        jsonObj.RoleId = rolePermission.RoleId;
        jsonObj.Status = rolePermission.Status?AppConfig.ActivityForm.ActiveStatusId:AppConfig.ActivityForm.InactiveStatusId;
        if(!creationFlag){
            jsonObj.Id = rolePermission.Id;
            jsonObj.Status = rolePermission.StatusId;
        }
    } catch (error) {
        console.log(error);
        jsonObj = false;
    }
    return jsonObj;
}
var SystemAdminService = {
    // SendEmail: () => {
    getHomePageConfig() {
        return new Promise((resolve, reject) => {
            axiosGetCall('/api/SystemAdmin/HomePageConfig')
                .then(homePageConfig => {
                    resolve(homePageConfig.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },    
    getBookedForUsers(jsonObj) {
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/SystemAdmin/BookedForUsers',{jsonObj})
                .then(bookedForUsers => {
                    resolve(bookedForUsers.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    getSystemAdmin() {
        return new Promise((resolve, reject) => {
            axiosGetCall('/api/SystemAdmin')
                .then(systemAdmin => {
                    resolve(systemAdmin.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },  
    getMaxDate() {
        return new Promise((resolve, reject) => {
            axiosGetCall('/api/SystemAdmin/MaxDate')
                .then(maxdateData => {
                    resolve(maxdateData.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    getSystemRolePermissions() {
        return new Promise((resolve, reject) => {
            axiosGetCall('/api/SystemAdmin/Permissions')
                .then(_p => {
                    resolve(_p.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    getSystemRolePermissionByRoleId(roleid) {
        return new Promise((resolve, reject) => {
            axiosGetCall(`/api/SystemAdmin/Permissions/${roleid}`)
                .then(_p => {
                    resolve(_p.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },  
    getHomePageConfigById(configId) {
        return new Promise((resolve, reject) => {
            axiosGetCall(`/api/SystemAdmin/HomePageConfig/${configId}`)
                .then(_p => {
                    resolve(_p.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    createSystemRolePermissions(rolePermission) {
        let payLoadObj = procesSystemRolePermissions(rolePermission,true); 
            return new Promise((resolve, reject) => {
            axiosPostCall('/api/SystemAdmin/Permissions',{
                    jsonObj: payLoadObj,
                })
                .then(_p => {
                    resolve(_p.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    getSystemRoles() {
        return new Promise((resolve, reject) => {
            axiosGetCall('/api/SystemAdmin/Roles')
                .then(_p => {
                    resolve(_p.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    getSystemRoleById(id) {
        return new Promise((resolve, reject) => {
            axiosGetCall(`/api/SystemAdmin/Roles/${id}`)
                .then(_p => {
                    resolve(_p.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    createSystemRoles(newRole) {
        let payLoadObj = procesSystemRoles(newRole,true);
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/SystemAdmin/Roles',{
                    jsonObj: payLoadObj,
                })
                .then(_p => {
                    resolve(_p.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    CreateHomePageConfig(newHomePageConfig) {
        let payLoadObj = processHomePageConfig(newHomePageConfig,true);
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/SystemAdmin/HomePageConfig',{
                    jsonObj: payLoadObj,
                })
                .then(_p => {
                    resolve(_p.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    updateSystemRoles(newRole) {
        let payLoadObj = procesSystemRoles(newRole,false);
        return new Promise((resolve, reject) => {
            axiosPutCall('/api/SystemAdmin/Roles',{
                    jsonObj: payLoadObj,
                })
                .then(_p => {
                    resolve(_p.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    UpdateHomePageConfig(newHomePageConfig, isHeading) {
        let payLoadObj = processHomePageConfig(newHomePageConfig,false);
        payLoadObj.isHeading = isHeading;
        return new Promise((resolve, reject) => {
            axiosPutCall('/api/SystemAdmin/HomePageConfig',{
                    jsonObj: payLoadObj,
                })
                .then(_p => {
                    resolve(_p.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    getAllUserRoles() {
        return new Promise((resolve, reject) => {
            axiosGetCall('/api/SystemAdmin/UserRoles')
                .then(_p => {
                    resolve(_p.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    getUserRoles(currentUser) {
        return new Promise((resolve, reject) => {
            axiosGetCall(`/api/SystemAdmin/UserRoles/${currentUser}`)
                .then(userRoles => {
                    resolve(userRoles.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    getUserRolesById(id) {
        return new Promise((resolve, reject) => {
            axiosGetCall(`/api/SystemAdmin/UserRoles/GetById/${id}`)
                .then(userRole => {
                    resolve(userRole.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    createUserRole(userRole) {
        let payLoadObj = procesSystemUserRoles(userRole,true);
        return new Promise((resolve, reject) => {
            axiosPostCall(`/api/SystemAdmin/UserRoles`,{
                    jsonObj: payLoadObj,
                })
                .then(userRoles => {
                    resolve(userRoles.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    updateUserRole(userRole) {
        let payLoadObj = procesSystemUserRoles(userRole,false);
        return new Promise((resolve, reject) => {
            axiosPutCall(`/api/SystemAdmin/UserRoles`,{
                    jsonObj: payLoadObj,
                })
                .then(userRoles => {
                    resolve(userRoles.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
    deleteMasterRecordById(deleteObj) {
        return new Promise((resolve, reject) => {
            let payLoadObj = processDelete(deleteObj);
            if (!payLoadObj) {
                let msg = 'Error in processing Data. Master data record Id not found';
                reject(msg);
            }
            axiosPutCall('/api/SystemAdmin/Master/Delete',{jsonObj:payLoadObj})
                .then(shifts => {
                    resolve(shifts.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        });
    },
};
export default SystemAdminService;
