import { strict } from "assert";

export const  HomePageConfig = {
    HeaderText: "<div>Welcome to SWFT. Please use this tool to plan all your lab and office activities.</div><div>If you need help, please try the help guides and videos on the right. If you are still having problems then please <a href='https://forms.office.com/Pages/ResponsePage.aspx?id=DpvWqfKXUkmc7miDkuQ4tN7XxzwPpIZImNnRJywlQoBURVYxTkJKUlRaRkxDS1VMM01UVEpGNFpSNS4u' target='_blank'>submit a helpdesk ticket.</a></div>",    
    ActionLinks: [
        {
            Title: 'Plan an Experiment',
            Icon: '',
            Tooltip: '',
            LinkURL: '/PlanExperiment',
            RedirectKey: '1',
        },
        {
            Title: 'Plan Lab Activity',
            Icon: '',
            Tooltip: '',
            LinkURL: '/PlanActivity',
            RedirectKey: '21',
            isExternalLink:false
        },
        {
            Title: 'Plan Office Usage',
            Icon: '',
            Tooltip: '',
            LinkURL: '/PlanOfficeSpace',
            RedirectKey: '3',
            isExternalLink:false
        },
        {
            Title: 'View Calendar',
            Icon: '',
            Tooltip: '',
            LinkURL: '/Calendar',
            RedirectKey: '4',
            isExternalLink:false
        },
        {
            Title: 'View My Activities',
            Icon: '',
            Tooltip: '',
            LinkURL: '/MyActivities',
            RedirectKey: '5',
            isExternalLink:false
        }
    ],
    GuideLinks: [
        {
            Title: 'Introduction to SWFT',
            Icon: 'PDF',
            Tooltip: '',
            LinkURL: '',
            RedirectKey: '',
            isExternalLink:true,
        },
        {
            Title: 'Quick Overview',
            Icon: 'MyMoviesTV',
            Tooltip: '',
            LinkURL: '',
            RedirectKey: '',
        },
        {
            Title: 'Plan an Experiment',
            Icon: 'MyMoviesTV',
            Tooltip: '',
            LinkURL: '',
            RedirectKey: '',
        },
        {
            Title: 'Plan Lab Activity',
            Icon: 'MyMoviesTV',
            Tooltip: '',
            LinkURL: '',
            RedirectKey: '',
        },
        {
            Title: 'Plan Office Usage',
            Icon: 'MyMoviesTV',
            Tooltip: '',
            LinkURL: '',
            RedirectKey: '',
        },
        {
            Title: 'Clone an Experiment or Activity',
            Icon: 'MyMoviesTV',
            Tooltip: '',
            LinkURL: '',
            RedirectKey: '',
        }
    ]
    
};
