import * as React from 'react';
import * as _ from 'lodash';
import {formatActivityDate} from './ActivityService';
import {
    MessageBar,
    MessageBarType,
} from 'office-ui-fabric-react/lib/MessageBar';
import FLatDatePickr from '../utils/FLatDatePickr';
import { IActivityDateOffsetProps, IActivityDateOffsetState } from './interfaces/IActivityDateOffset';
import { IActivityShift } from './interfaces/IActivityForm';
import AppConfig from '../../Constants';

const moment =  require('moment');


export default class ActivityDateOffset extends React.Component<IActivityDateOffsetProps,IActivityDateOffsetState> {
    constructor(props:IActivityDateOffsetProps) {
        super(props);
        this.state = {
            activityDate: new Date(formatActivityDate()),
            errorMsgs:{},
            Offset:0,
            isPast:false,
            ExpDate:null,
            MaxDate:"02/28/2021"
        };
    }
    componentDidMount() {
        const {errorMsgs,activityDate,ExpDate,isPast,MaxDate} = this.props;
        let expDate = moment(ExpDate);
        let actDate = moment(activityDate);
        let offset = actDate.diff(expDate,'days');
        this.setState({
            activityDate,
            errorMsgs,
            isPast,
            ExpDate,
            Offset:offset,
            MaxDate
        });
    }
    componentDidUpdate(prevProps:IActivityDateOffsetProps, prevState:IActivityDateOffsetState) {
        const {errorMsgs,activityDate,ExpDate,isPast,MaxDate} = this.props;
        if (prevProps.MaxDate != MaxDate) {
            this.setState({
                MaxDate
            });
        }
        if (prevProps.activityDate != activityDate) {
            this.setState({
                activityDate
            });
        }
        if (prevProps.isPast != isPast) {
            this.setState({
                isPast
            });
        }
        if(errorMsgs.activityDate!==prevProps.errorMsgs.activityDate || errorMsgs.selectedShiftIds!==prevProps.errorMsgs.selectedShiftIds ){
            this.setState({
                errorMsgs
            });
        }
        if(ExpDate && prevProps.ExpDate){
            let prevExpDate = moment(prevProps.ExpDate);
            let expDate = moment(ExpDate);
            let dateDiff = expDate.diff(prevExpDate,'days',true);
            if(dateDiff !== 0){
                let offset  = this.getOffsetValue(activityDate,ExpDate);
                this.setState({
                    Offset:offset,
                    ExpDate
                });
            }
        }
        if(ExpDate && prevProps.ExpDate){
            let prevActDate = moment(prevProps.activityDate);
            let actDate = moment(activityDate);
            let dateDiff = actDate.diff(prevActDate,'days',true);
            if(dateDiff !== 0){
                let offset  = this.getOffsetValue(activityDate,ExpDate);
                this.setState({
                    Offset:offset,
                    ExpDate
                });
            }
        }
    }  
    getOffsetValue =(date1:any,date2:any) :number => {
        let expDate = moment(date1);
        let actDate = moment(date2);
        return  Math.ceil(expDate.diff(actDate,'days',true));
    }  
    handleDateSelectChange = (date:any) => {
        const {activityDate,ExpDate}:any = this.state;
        if (
            date &&
            formatActivityDate(date) != formatActivityDate(activityDate)
        ) {
            let offset  = this.getOffsetValue(date,ExpDate);
            this.setState({
                activityDate: date,
                Offset:offset
            });
        }
        this.props.handleFormFieldChange(date, 'activityDate');
    }
    renderErrorMsgForField(msg:string,key:any){
        return(
             <div role="alert" key={key}>
                <p className="ms-TextField-errorMessage alertMsg">
                    <span data-automation-id="error-message">{msg}</span>
                </p>
            </div>
        );
    }
    render() {
        const { errorMsgs,activityDate,Offset,isPast,MaxDate} = this.state;
        const {viewForm, ExpId} = this.props;
        return (
            <>
                {ExpId ? (<div style={{width: "53%",display: 'flex'}}>
                    <div style={{width: "76%"}}>
                        <FLatDatePickr
                            required={true}
                            viewForm={viewForm}
                            isPast={isPast}
                            maxDate={MaxDate}
                            labelHead="Activity Date"
                            updateFunc={this.handleDateSelectChange}
                            dateInput={activityDate}
                        />
                        {errorMsgs.activityDate?this.renderErrorMsgForField(errorMsgs.activityDate,'activityDateError'):''}
                    </div>
                    <div style={{width: "5%"}}></div>
                    <div style={{width: "20%",marginBottom:'10px',alignSelf: "flex-end",fontSize:"13px"}}>
                        {(Offset && Offset<0)?"Err":"T + "+ Offset}
                    </div>
                </div>
                ):(
                    <div style={{width: "53%"}}>
                        <FLatDatePickr
                            required={true}
                            viewForm={viewForm}
                            isPast={isPast}
                            maxDate={MaxDate}
                            labelHead="Activity Date"
                            updateFunc={this.handleDateSelectChange}
                            dateInput={activityDate}
                        />
                        {errorMsgs.activityDate?this.renderErrorMsgForField(errorMsgs.activityDate,'activityDateError'):''}
                    </div>
                )}
            </>
        );
    }
}