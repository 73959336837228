import * as React from 'react';
import { Dropdown, Label, TextField,IDropdownOption, IPersonaProps, Link, Spinner, SpinnerSize, MessageBar, MessageBarType, Icon } from '@fluentui/react';
import FormWrapper from '../FormWrapper';
import { IActivityFormState, IActivityShift, IActivityEquipment, IActivity, IUserPersonaField, IActivityFormProps } from './interfaces/IActivityForm';
import { createActivity, formatActivityDate, formatDateWithFrmt, getDropDownOptions, getLocationEquipments, getProjectData, getShiftData, getUniqueLocationObjs, sendVisitorEmail, today, validateEmail } from './ActivityService';
import AppConfig from '../../Constants';
import _ from 'lodash';
import { IProject } from '../Experiment/interfaces/IExperiment';
import PersonVisitor from './PersonVisitor';
import LocationsAndEquipments from './LocationsAndEquipments';
import { IShiftTimings } from './interfaces/IOutlookSync';
import ActivityDateOffset from './ActivityDateOffset';
import LocationShiftAvailability from './LocationShiftAvailability';
import { BlockingSpinner } from '../Common/dialogs/BlockingSpinner';
import ActionScreen from '../Common/ActionScreen';
import { Redirect } from 'react-router-dom';
import ButtonWithDialog from '../Common/dialogs/ButtonWithDialog';
import { UserInfoContext } from '../../Main';

import '../Forms.css';
import './ActivityForm.css';
import { ILocationShiftAvailability } from './interfaces/ILocationShiftAvailability';
import { v4 as uuidv4 } from 'uuid';
import { IEmailObj } from './interfaces/IEmailObj';
import { SyncService } from './OutLookEventSync/SyncService';

const moment =  require('moment');

const descFieldClass = {
    wrappetr: {
        width: '100%',
        display: 'block',
    },
    root: {
        width: '100%',
    },
    field: {
        height: 200,
    },
};

let InitialErrorMsg = {
    activityDate: '',
    selectedShiftIds: '',
    activityName: '',
    activityDescription: '',
    bookedForEmail: '',
    visitorEmail: '',
    selectedLocations: '',
};
export default class ActivityForm extends React.Component<IActivityFormProps, IActivityFormState> {
    constructor(props: IActivityFormProps) {
        super(props);
        ActivityForm.contextType = UserInfoContext;
        this.state = {
            newActivity: {
                activityDate: new Date(formatActivityDate()),
                ExperimentId:null,
                DayOffset:0,
                ProjectId:0,
                selectedShiftIds: [],
                activityName: '',
                activityDescription: '',
                bookedForEmail: [],
                bookedByEmail: {} as IUserPersonaField,
                visitorEmail: '',
                selectedLocations: [],
                selectedEquipments: [],
                showVisitorField: false,
            },
            Projects:[],
            ShiftTimmings:[],
            Locations: [],
            Equipments: [],
            selectedFor:[], 
            formFieldKey:uuidv4(),
            locationEquipKey:uuidv4(),
            errorMsg: '',
            errorMsgs: {
                activityDate: '',
                selectedShiftIds: '',
                activityName: '',
                activityDescription: '',
                bookedForEmail: '',
                visitorEmail: '',
                selectedLocations: '',
            },
            LsaArr: []  as ILocationShiftAvailability[],
            isLoaded: false,
            showErrorMsg: false,
            CreatedId:0,
            DisableProject: false,
            SaveFlag: false,
            isCreated: false,
            hideCancelDialog: true,
            isRedirect: false,
            LSAFullyBooked: false,
            actionType:"Create"
        }
    }
    private projectsDropDownOptions: IDropdownOption[] = [];
        
    componentDidMount = () =>{
        const promises:any = [];
        promises.push(getShiftData());
        promises.push(getLocationEquipments());
        promises.push(getProjectData());
        Promise.all(promises).then((responses:any) => {
        this.setState({isLoaded:true});
            if (responses.length > 0) {
                let shifts:IActivityShift[] = responses[0] ? _.filter(responses[0],{StatusId:AppConfig.ActivityForm.ActiveStatusId}) : [];
                let locationEquipmentData:IActivityEquipment[] = responses[1]? responses[1]: [];
                let projectData:IProject[] = responses[2]? responses[2]: [];
                this.arrangeFormFields(shifts, locationEquipmentData,projectData);
            }
            else {
                this.setState({
                    isLoaded:true,
                    showErrorMsg: true,
                    errorMsg: AppConfig.ActivityForm.ErrorDataFetchMessage
                });
            }
        })
        .catch(error => {
            this.setState({
                isLoaded:true,
                showErrorMsg: true,
                errorMsg: AppConfig.ActivityForm.ErrorDataFetchMessage
            });
            console.log(error);
        });
    }
    componentDidUpdate(prevProps:IActivityFormProps, prevState:IActivityFormState) {
        const {ExpData} = this.props;
        const {newActivity} = this.state;
        if(ExpData && prevProps.ExpData){
            if(ExpData.ExpOwnerEmail !== prevProps.ExpData.ExpOwnerEmail){
                newActivity.ExperimentId = ExpData.ExpId;
                newActivity.ProjectId = ExpData.ProjectId;
                newActivity.ExperimentOwner = ExpData.ExpOwnerEmail;
                newActivity.ExperimentStartDate = ExpData.ExpStartDate;
                newActivity.activityDate = this.checkExpDateIsPast(ExpData.ExpStartDate);
                this.setState({
                    newActivity
                });
            }
            if(ExpData.ProjectId !== prevProps.ExpData.ProjectId){
                newActivity.ProjectId = ExpData.ProjectId;
                this.setState({
                    newActivity
                });
            }
            let prevExpDate = moment(prevProps.ExpData.ExpStartDate);
            let expDate = moment(ExpData.ExpStartDate);
            let dateDiff = expDate.diff(prevExpDate,'days');
            if(dateDiff !== 0){
                newActivity.ExperimentId = ExpData.ExpId;
                newActivity.ExperimentOwner = ExpData.ExpOwnerEmail;
                newActivity.ExperimentStartDate = ExpData.ExpStartDate;
                newActivity.activityDate = this.checkExpDateIsPast(ExpData.ExpStartDate);
                this.setState({
                    newActivity,
                    formFieldKey:uuidv4()
                });
            }
            if(ExpData.ExperimentNotSaved !== prevProps.ExpData.ExperimentNotSaved){
                newActivity.ExperimentId = ExpData.ExpId;
                newActivity.ProjectId = ExpData.ProjectId;
                newActivity.ExperimentOwner = ExpData.ExpOwnerEmail;
                newActivity.ExperimentStartDate = ExpData.ExpStartDate;
                newActivity.activityDate = this.checkExpDateIsPast(ExpData.ExpStartDate);
                this.setState({
                    newActivity,
                    formFieldKey:uuidv4()
                });
            }
        }
    }
    CheckIfTodayIsPastMinDate= ():boolean=>{
        var minDate = moment(AppConfig.ActivityForm.MinDate),
        currDate = moment();
        let diffDates = currDate.diff(minDate,'days');
        if( diffDates>0){
            return true;
        }
        return false;
    }
    arrangeFormFields = (shifts:IActivityShift[], locationEquipmentData:IActivityEquipment[],projectData:IProject[]) => {
        let currentUserName:string = this.context.name;
        let currentUserEmail:string = this.context.userName;
        let selectedShiftIds:number[] = [];

        let shiftsArr:IActivityShift[] = this.prepareShiftsData(shifts);
        let uniqLocationsArr = getUniqueLocationObjs(locationEquipmentData, this.props.office);
        this.projectsDropDownOptions = getDropDownOptions(projectData,'Name');
        const {newActivity} = this.state;
        const {ExpData} = this.props;
        newActivity.selectedShiftIds = selectedShiftIds;
        newActivity.bookedByEmail.DisplayName = currentUserName;
        newActivity.bookedByEmail.Email= currentUserEmail;
        var obj:IUserPersonaField = {} as IUserPersonaField;
        newActivity.bookedForEmail.push(obj);
        newActivity.bookedForEmail[0].DisplayName = currentUserName;
        newActivity.bookedForEmail[0].UserLogin = currentUserEmail;
        newActivity.bookedForEmail[0].Email = currentUserEmail;
        let disableProjectIfExp:boolean = false;
        if(ExpData){    
            disableProjectIfExp = true;    
            newActivity.ExperimentId = ExpData.ExpId;
            newActivity.ProjectId = ExpData.ProjectId;
            newActivity.ExperimentOwner = ExpData.ExpOwnerEmail;
            newActivity.activityDate = this.checkExpDateIsPast(ExpData.ExpStartDate);
            newActivity.ExperimentStartDate = ExpData.ExpStartDate;
        }
        let CheckIfTodayIsPastMinDate = this.CheckIfTodayIsPastMinDate();
        if(!CheckIfTodayIsPastMinDate && !ExpData){
            newActivity.activityDate = new Date(formatActivityDate(AppConfig.ActivityForm.MinDate));
        }
        this.setState({
            ShiftTimmings: shiftsArr,
            Projects:projectData,
            DisableProject:disableProjectIfExp,
            newActivity: newActivity,
            Locations: uniqLocationsArr,
            Equipments:locationEquipmentData,
            isLoaded: true,
            selectedFor:[...this.state.selectedFor, newActivity.bookedForEmail[0]],
            locationEquipKey: uuidv4(),
        });
        if(ExpData){
            if(this.props.handleActivityActions){
                this.props.handleActivityActions("Form Loaded", "LoadForm");
            }
        }
    }
    prepareShiftsData = (shifts:IActivityShift[]):IActivityShift[] =>{
        var shiftArr:IActivityShift[] = [];
        if (!AppConfig.ActivityForm.showTBD) {
            shifts = _.reject(shifts, {DisplayName: AppConfig.ActivityForm.TBDRejectDispName});
            shifts.map(element => {
                return (element.isSelected = false);
            });
            shiftArr = shifts;
        } else {
            //arrange TBD to be first place
            let tbdShiftArr = _.filter(shifts, {DisplayName: AppConfig.ActivityForm.TBDRejectDispName});
            if (tbdShiftArr && tbdShiftArr.length > 0) {
                let tbdShift = tbdShiftArr[0];
                tbdShift.isSelected = false;
                shifts = _.reject(shifts, {DisplayName: AppConfig.ActivityForm.TBDRejectDispName});
                shiftArr.push(tbdShift);
                for (let sndex = 0; sndex < shifts.length; sndex++) {
                    const shiftElm = shifts[sndex];
                    shiftElm.isSelected = false;
                    shiftArr.push(shiftElm);
                }
                // selectedShiftIds = [AppConfig.ActivityForm.TBDRejectShiftId];
            } else {
                for (let sndex = 0; sndex < shifts.length; sndex++) {
                    const shiftElm = shifts[sndex];
                    shiftElm.isSelected = false;
                    shiftArr.push(shiftElm);
                }
            }
        }
        return shiftArr;
    }
    checkExpDateIsPast = (expDate:any) =>{
        var currExpDate = moment(expDate);
        var currDate = moment(today);
        var diffDates = currDate.diff(currExpDate,'days');
        if(expDate && diffDates>0){
            return new Date(formatActivityDate());
        }
        else{
            return expDate;
        }
    }  
    onFilterChanged=(filterText: string, items: IPersonaProps[] | undefined) : IPersonaProps[] => {
        return [];
    }

    handleActDateChange = (updateProp:any, field:string) =>{
        const {newActivity,LsaArr} :any = this.state;
        newActivity[field] = updateProp;
        this.setState({
            newActivity,
            showErrorMsg: false,
            errorMsg: ''
        }); 
    }
    handleTextFieldsChange  = (event:any,newValue:any) =>{
        const {newActivity} :any = this.state;
        let fieldName:any = event.target.name;
        let value:any = newValue;
        if(value.trim() === '') {
            newActivity[fieldName] = '';
        } else {
            newActivity[fieldName] = value;
        }
        this.setState({newActivity});
    }
    handleProjectChange  = (event:any,option:IDropdownOption|undefined) =>{
        const {newActivity} = this.state;
        if(option){
            newActivity.ProjectId = parseInt(option.key.toString())
            this.setState({newActivity});
        }
    }
    handlePersonVisitorChange = (eventObj:any) => {
        const {newActivity,LsaArr} :any = this.state, val = eventObj.target.value;
        if(eventObj.target.name === 'visitorEmail'){
            let rasieAlert = this.alertLSAFullyBooked(LsaArr,val);
            newActivity[eventObj.target.name] = val;
            this.setState({
                newActivity,
                showErrorMsg: rasieAlert,
                errorMsg: rasieAlert? AppConfig.ActivityForm.FullyBookedAlertMsg:''
            });
        }
        else if(eventObj.target.name === "bookedForEmail"){
            let rasieAlert = val.length>0?this.alertLSAFullyBooked(LsaArr,val[0].Email):false;
            newActivity[eventObj.target.name] = val;
            this.setState({
                newActivity,
                selectedFor:eventObj.target.selectedFor,
                showErrorMsg: rasieAlert,
                errorMsg: rasieAlert? AppConfig.ActivityForm.FullyBookedAlertMsg:''
            });
        }
        else if(eventObj.target.name === "showVisitorField"){
            let rasieAlert = this.alertLSAFullyBooked(LsaArr,val);
            newActivity[eventObj.target.name] = val;
            // newActivity.bookedForEmail = [];
            newActivity.visitorEmail = '';
            this.setState({
                newActivity,
                showErrorMsg: rasieAlert,
                errorMsg: rasieAlert? AppConfig.ActivityForm.FullyBookedAlertMsg:''
            });
        }
        else{
            this.setState({
                newActivity: newActivity,
            });
        }
    }
    handleEquipmentSelection = (updateProp:any, field:string) => {
        let newActivity:any= this.state.newActivity;
        newActivity[field] = updateProp;
        this.setState({
            newActivity,
        });
    }
    handleLocationSelection = (locationValues:any, equipValues:string) => {
        const {newActivity,LsaArr} :any = this.state;
        newActivity['selectedLocations'] = locationValues;
        newActivity['selectedEquipments'] = equipValues;
        var rasieAlert = this.alertLSAFullyBooked(LsaArr);
        this.setState({
            newActivity,
            showErrorMsg: rasieAlert,
            errorMsg: rasieAlert?AppConfig.ActivityForm.FullyBookedAlertMsg:''
        });
    }
    handleShiftChange = (event:any) =>{
        var options:any = event.target.options;
        const {newActivity, LsaArr} :any = this.state;
        var values:number[]  = this.getOptionsSelected(options);
        var rasieAlert = this.alertLSAFullyBooked(LsaArr);

        newActivity.selectedShiftIds = values;
        this.setState({
            newActivity,
            showErrorMsg:rasieAlert,
            errorMsg: rasieAlert?AppConfig.ActivityForm.FullyBookedAlertMsg:''
        });
    }
    handleMultiSelectClear = (event:any) => {
        let fieldName:any = event.target.name;
        const {newActivity} = this.state;
        if (fieldName  === "Shift") {
            newActivity.selectedShiftIds = [];
            this.setState({newActivity});
        }
    }
    updateStateLSA = (lsaArr:any) => {
        var raiseError = this.alertLSAFullyBooked(lsaArr);
        const{showErrorMsg} = this.state;
        this.setState({
            LsaArr:lsaArr,
            showErrorMsg:showErrorMsg?showErrorMsg:raiseError,
            errorMsg: AppConfig.ActivityForm.FullyBookedAlertMsg
        });
    }

    handleFormSaveClick = (ev:any) => {
        ev.preventDefault();
        const {newActivity,LSAFullyBooked,LsaArr} = this.state;
        if(LSAFullyBooked){
            this.setState({
                LSAFullyBooked:false
            });
        }else{
            var flag = this.validateFields(newActivity);
            if (flag) {
                this.setState({
                    showErrorMsg: false,
                    errorMsg: '',
                    errorMsgs: InitialErrorMsg,
                });
                var alertFlag = this.alertLSAFullyBooked(LsaArr);
                if(alertFlag){
                    this.setState({
                        LSAFullyBooked:true
                    });
                }
                else{
                    this.callForCreate(newActivity,AppConfig.ActivityForm.OfcAndIndiActivityType);
                }
            } else {
                this.setState({
                    showErrorMsg: true,
                    errorMsg: AppConfig.ActivityForm.ErrorValidationMessage,
                });
            }
        }
    }
    handleFormSaveClickAction = () =>{
        const {newActivity} = this.state;
        this.callForCreate(newActivity,AppConfig.ActivityForm.OfcAndIndiActivityType);
    }
    handleCancelClick = () =>{
        const {hideCancelDialog} = this.state;
        this.setState({
            hideCancelDialog:!hideCancelDialog
        });
    }
    handleCancelAction = () =>{
        if(this.props.ExpData){
            if(this.props.handleActivityActions){
                this.props.handleActivityActions('', "CloseForm");
            }
        }else{
            this.setState({
                isRedirect:true
            });
        }
    }
    

    renderErrorMsgForField(msg:string,key:any){
        return(
             <div role="alert" key={key}>
                <p className="ms-TextField-errorMessage alertMsg">
                    <span data-automation-id="error-message">{msg}</span>
                </p>
            </div>
        );
    }
    renderErrorMsg(msg:string){
        return(
            <MessageBar messageBarType={MessageBarType.error} className={"errorMsgInfo"}>
                {msg}
            </MessageBar>
        );
    }
    loadSpinner = (labelTxt:string,isLoading:boolean) :JSX.Element=> {
        return (
            <div className="centeredContainer">
                <BlockingSpinner label={labelTxt} hideDialog={!isLoading}/>
            </div>
        );
    } 
    public render() {
        const {office,NotPopActions,ExpData} =this.props;
        const {ShiftTimmings, Locations, Equipments, isLoaded, newActivity, errorMsgs, errorMsg, formFieldKey, selectedFor, locationEquipKey,
            CreatedId, showErrorMsg, SaveFlag, isRedirect,isCreated,actionType,LSAFullyBooked,hideCancelDialog,DisableProject} = this.state;
        const dialogMsg = AppConfig.ActivityForm.FullyBookedDialogMsg;
        const formHeading = 'Lab Activity';
        const redirectURL = '/PlanActivity?Id='+CreatedId;

        return (
            <div className="formMain">
            {isLoaded ? (
                <> 
                    <FormWrapper FormHeading={"Add " +formHeading}>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row" style={{marginBottom:"5px"}}>
                                <div className="ms-Grid-col ms-sm12">
                                    <div className="ms-Grid-col ms-sm4"> 
                                        <div style={{width: "100%",display:"flex",justifyContent:"flex-start"}}>
                                            <ActivityDateOffset key={formFieldKey.toString()}
                                                activityDate={newActivity.activityDate}
                                                ExpId={newActivity.ExperimentId}
                                                ExpDate={newActivity.ExperimentStartDate}
                                                errorMsgs={errorMsgs}
                                                handleFormFieldChange={this.handleActDateChange}/>
                                            <div style={{width: "2%"}}></div>
                                            <div style={{width: "45%"}}>
                                                <Dropdown
                                                    required
                                                    placeholder="Select Project / Study"
                                                    label="Project / Study"
                                                    disabled={DisableProject}
                                                    selectedKey={newActivity.ProjectId}
                                                    options={this.projectsDropDownOptions}
                                                    errorMessage={errorMsgs.ProjectId}
                                                    onChange={this.handleProjectChange}
                                                />
                                            </div>
                                        </div>
                                        <TextField 
                                            required
                                            label="Activity Name"
                                            name="activityName"
                                            placeholder="Activity Name" 
                                            value={newActivity.activityName}
                                            errorMessage={errorMsgs.activityName}
                                            onChange={this.handleTextFieldsChange}/>
                                        <TextField 
                                            multiline={true} 
                                            rows={3} 
                                            style={{height:"50px"}} 
                                            label="Activity Description" 
                                            name="activityDescription"
                                            placeholder="Activity Description"
                                            value={newActivity.activityDescription}
                                            errorMessage={errorMsgs.activityDescription}
                                            onChange={this.handleTextFieldsChange}
                                            styles={descFieldClass}/>       
                                        <PersonVisitor key={formFieldKey.toString()}
                                            errorMsgs ={errorMsgs}
                                            selectedFor={selectedFor}                                        
                                            showVisitorField ={newActivity.showVisitorField}
                                            visitorEmail={newActivity.visitorEmail}
                                            handleOnChange={this.handlePersonVisitorChange}/>  
                                        <div style={{width: "100%",display:"flex",justifyContent:"space-between"}}>
                                            <div style={{width: "31%"}}>
                                                <div style={{display:"flex",alignItems:"center",justifyContent:"left"}}>
                                                    <Label className={'requiredLabel'} title={'Use SHIFT to select a range of shifts,\nUse CTRL to select non-contiguous shifts.'}>Shift(s)</Label>
                                                    <div style={{fontSize:12}}>
                                                        <Icon style={{fontSize:"14px",cursor:"pointer",verticalAlign:"middle"}} iconName={'Info'}  
                                                            title={'Use SHIFT to select a range of shifts,\nUse CTRL to select non-contiguous shifts '}/>
                                                    </div>
                                                </div>
                                                <select 
                                                    title={'Use SHIFT to select a range of shifts,\nUse CTRL to select non-contiguous shifts.'}
                                                    style={{width: "100%", height: "200px",fontSize:"12px", color:"black!important"}}
                                                    multiple={true}
                                                    id={"selectedShiftIds"}
                                                    required={true}
                                                    onChange={e => this.handleShiftChange(e)}>
                                                        {this.renderShifts(ShiftTimmings,newActivity.selectedShiftIds)}
                                                </select>
                                                <Link name={"Shift"} onClick ={this.handleMultiSelectClear} style={{fontSize:"12px", color:"black!important"}}>Clear</Link>
                                                {errorMsgs.selectedShiftIds?this.renderErrorMsgForField(errorMsgs.selectedShiftIds,'selectedShiftIdsError'):''}
                                            </div>
                                            <LocationsAndEquipments key={locationEquipKey}
                                                locationEquipKey={locationEquipKey}
                                                Equipments={Equipments}
                                                Locations={Locations}
                                                errorMsgs={errorMsgs}
                                                selectedLocations={newActivity.selectedLocations}
                                                selectedEquipments={newActivity.selectedEquipments}
                                                handleLocationSelectionChange={this.handleLocationSelection}
                                                handleEquipmentSelectionChange={this.handleEquipmentSelection}/>
                                        </div>
                                    </div>                    
                                    <div  className="ms-Grid-col ms-sm8 shiftLocation heightFix" >
                                    <LocationShiftAvailability key={formFieldKey.toString()}
                                            selectedLocations={newActivity.selectedLocations}
                                            sendLsaArr ={this.updateStateLSA}
                                            Shifts={ShiftTimmings}
                                            office={false}
                                            Locations={Locations}
                                            activityDate={formatActivityDate(newActivity.activityDate)}/>
                                    </div>
                                </div>                                                             
                            </div>  
                            {showErrorMsg?this.renderErrorMsg(errorMsg):''}
                            <div className={ExpData?.ExperimentNotSaved?"displayNone":"row formHeadflex"} key="divButtons">
                                <div className="button-right" >
                                    <div className="leftBtns" >
                                        <ButtonWithDialog key={'Save'} 
                                                CustClassName ={"BlockingDialog"}
                                                iconString={'CircleAddition'}
                                                hideDialog={!LSAFullyBooked}
                                                buttonTxt={'Add'}
                                                onBtnClick={this.handleFormSaveClick}
                                                buttonType={"Primary"}
                                                dialogTitle={'Location Fully Booked'}
                                                subText={dialogMsg+' Create '+formHeading+'?'}
                                                isBlocking={false}
                                                handleAction={this.handleFormSaveClickAction}
                                                actionBtnTxt={'Yes'}
                                                dismisBtnTxt={'No'}>                                            
                                        </ButtonWithDialog>
                                    <ButtonWithDialog key={'Cancel'}
                                                CustClassName ={"BlockingDialog"}
                                                iconString={'Cancel'}
                                                hideDialog={hideCancelDialog}
                                                buttonTxt={'Cancel'}
                                                onBtnClick={this.handleCancelClick}
                                                buttonType={'Default'}
                                                dialogTitle={'Cancel?'}
                                                subText={'Changes made to '+formHeading+' will not be saved.'}
                                                isBlocking={false}
                                                handleAction={this.handleCancelAction}
                                                actionBtnTxt={'Yes'}
                                                dismisBtnTxt={'No'}>                                            
                                        </ButtonWithDialog>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FormWrapper>
                </>
                ) : (
                <div className="centeredContainer">
                    <Spinner size={SpinnerSize.large} />
                </div>
                )}
                 {SaveFlag?(
                    this.loadSpinner('Creating '+formHeading +'...',SaveFlag)
                ):''}
                {isRedirect?( <Redirect to={'/Home'} /> ):''}
                {isCreated && !NotPopActions?(
                        <ActionScreen
                            subText= {formHeading+' Created. Redirecting to edit page...'}
                            dialogTitle= {'Created Successfully'}
                            actionBtnTxt= {'go Home'}
                            showModal= {isCreated}
                            isBlocking={true}
                            actionType ={actionType}
                            shouldDefaultRedirect= {true}
                            defaulltRedirectURI= {redirectURL}
                            timmer={AppConfig.ActivityForm.FormRedirectionTimmer}
                        />
                ):''}
            </div>
        );
    }
    renderShifts = (shifts:IActivityShift[],selectedShiftIds:number[]) => {
        let options:any = shifts.map((shift:IActivityShift, key:any) => {
            if(selectedShiftIds.indexOf(shift.Id)!==-1){
                return (
                    <option
                        id={shift.Id?.toString()}
                        key={key + shift.Name}
                        value={shift.Id}
                        selected={true}>
                            {shift.DisplayName}
                    </option>
                );
            }
            else{
                return (
                    <option
                        id={shift.Id?.toString()}
                        key={key + shift.Name}
                        value={shift.Id}
                        selected={false}>
                            {shift.DisplayName}
                    </option>
                );
            }
        });
        return options;
    }
    getOptionsSelected = (options:any) => {
        var optionValues:number[] = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                optionValues.push(parseInt(options[i].id));
            }
        }
        return optionValues;
    }
    alertLSAFullyBooked = (LsaArr:ILocationShiftAvailability[],selectedUserEmail?:string):boolean =>{
        const {newActivity} = this.state;
        var fullCapLSA:ILocationShiftAvailability[] =  _.filter(LsaArr,{OnAlert:true}), raiseAlert:boolean = false;
        if(fullCapLSA.length>0){
            let filterSelection = _.filter(fullCapLSA,(lsa)=>{
                let shiftSelected = false;
                let locSelected = false;
                if(newActivity.selectedShiftIds.indexOf(lsa.ShiftId) !==-1){
                    shiftSelected = true;
                }
                if(newActivity.selectedLocations.indexOf(lsa.LocationId) !==-1){
                    locSelected = true;
                }                
                return shiftSelected && locSelected;
            });
            console.log(filterSelection);
            if(filterSelection.length>0){
                let bookedByEmail:string  =  "";
                if(!selectedUserEmail){
                    bookedByEmail = newActivity.showVisitorField?newActivity.visitorEmail:newActivity.bookedForEmail.length>0?newActivity.bookedForEmail[0].Email:"";
                }
                else{
                    bookedByEmail = selectedUserEmail;
                }
                for (let selectionIndex = 0; selectionIndex < filterSelection.length; selectionIndex++) {
                    const lsaObj:ILocationShiftAvailability = filterSelection[selectionIndex];
                    let noOfBookedFor:number = 0;
                    if(bookedByEmail && lsaObj.BookedForEmails.indexOf(bookedByEmail) === -1){
                        noOfBookedFor = lsaObj.BookedForEmails.length+1; 
                    }                 
                    if(noOfBookedFor>lsaObj.LocationCapacity){
                        raiseAlert = true;
                    }
                }
            }
        }
        return raiseAlert;
    }
    validateFields = (newActivity:IActivity) => {
        let fieldsArr = ['activityName','visitorEmail','ProjectId'],
            fieldValueArr:any = newActivity,
            flag = true,
            errorArr = ['Activity Name is required field.','Visitor Email is required field.'];
        const {ExpData} =  this.props;
            if(this.props.office){
                newActivity.selectedEquipments = [];
                newActivity.selectedLocations = [AppConfig.ActivityForm.officeSpaceUsageLocation];
            }
        try {
            for (let i = 0; i < fieldsArr.length; i++) {
                let eachField = fieldsArr[i];
                if(eachField === 'visitorEmail'){
                    if(newActivity.showVisitorField){
                        const emailId = fieldValueArr[eachField]; 
                        if(emailId === ''){
                            this.addValidationError(errorArr[i], fieldsArr[i], flag);
                            flag = false;
                        }
                        else{
                            let checkFlag = validateEmail(emailId);
                            if (!checkFlag) {
                                let errMsg ='Visitor Email field should contain valid email.';
                                this.addValidationError(errMsg, fieldsArr[i], flag);
                                flag = false;
                            }
                        }
                    }
                }
                else if(eachField === 'ProjectId'){
                    if(fieldValueArr[eachField] === 0 ){
                        let errMsg ='Project/Study is required field.';
                        this.addValidationError(errMsg, fieldsArr[i], flag);
                        flag = false;
                    }
                }
                else {
                    if(fieldValueArr[eachField] === '') {
                        this.addValidationError(errorArr[i], fieldsArr[i], flag);
                        flag = false;
                    }
                }
            }
            if (isNaN(newActivity.activityDate.getTime())) {
                this.addValidationError('Activity Date is required field.', 'activityDate', flag);
                flag = false;
            }
            else{
                var currDate = moment(formatActivityDate(today)),
                currActivityDate = moment(newActivity.activityDate);
                var diffDates = currDate.diff(currActivityDate,'days',true);//currdate-currActivityDate>0 meands currdate is ahead
                if( diffDates>=1){
                    this.addValidationError('Activity Date should be selected from future dates.', 'activityDate', flag);
                    flag = false;
                }
                else{
                    if(ExpData){
                        var currActivityDate = moment(newActivity.activityDate),
                        currDate = moment(),
                        expDate = moment(ExpData.ExpStartDate);
                        let diffDates = currDate.diff(currActivityDate,'days');
                        if( diffDates>0){
                            this.addValidationError('Activity Date should be selected from future dates.', 'activityDate', flag);
                            flag = false;
                        }
                        var diffExpDates = expDate.diff(currActivityDate,'days');
                        if(diffExpDates>0){
                            let errMsg:string ="Select Activity Date from future dates and should not be before experiment date.";
                            if(flag){
                                errMsg = 'Activity Date should be selected from future dates.';
                            }
                            this.addValidationError(errMsg, 'activityDate', flag);
                            flag = false;
                        }
                    }
                }               
            }
            if (newActivity.selectedShiftIds.length<1) {
                this.addValidationError('Shift is required field.', 'selectedShiftIds', flag);
                flag = false;
            }
            if (!newActivity.showVisitorField && newActivity.bookedForEmail.length<1) {
                this.addValidationError('Person Name is required field.', 'bookedForEmail', flag);
                flag = false;
            }
            if (newActivity.selectedLocations.length<1) {
                this.addValidationError('Equipment is required field.', 'selectedLocations', flag);
                flag = false;
            }
        } catch (error) {
            console.log(error);
            flag = false;
        }
        return flag;
    }
    addValidationError = (message:string, id:string, flushErrors:boolean) => {
        this.setState((previousState:any) => {
            let errorMessageArr:any = {};
            if (!flushErrors) {
                errorMessageArr = {...previousState.errorMsgs};
            }
            errorMessageArr[id] = message;
            return {
                errorMsgs: errorMessageArr,
            };
        });
    }
    callForCreate = (newActivity:IActivity,activityType:string) =>{
        this.setState({SaveFlag:true, LSAFullyBooked:false});       
        createActivity(newActivity,activityType).then((response:any) => {
            if(response){
                if(newActivity.showVisitorField){
                    let emailObj:IEmailObj = {} as IEmailObj;
                    emailObj.body = this.formVisitorEmailBody(newActivity);
                    emailObj.toRecipients =  process.env.REACT_APP_VISITOR_EMAIL_TO_ADD? process.env.REACT_APP_VISITOR_EMAIL_TO_ADD:"";
                    emailObj.subject = process.env.REACT_APP_VISITOR_EMAIL_SUBJECT? process.env.REACT_APP_VISITOR_EMAIL_SUBJECT:"";
                    sendVisitorEmail(emailObj).then((data)=>{
                        console.log(data);
                    }).catch((error) =>{
                        console.log(error);
                    });
                }
                this.setState({SaveFlag:false,CreatedId:response.id,isCreated:true,actionType:"Create"});
                if(this.props.ExpData){
                    if(this.props.handleActivityActions){
                        this.props.handleActivityActions(newActivity, "CreatedActivity");
                    }
                }               
                
            }else{
                this.setState({
                    SaveFlag:false,
                    showErrorMsg: true,
                    actionType:"Failed",
                    errorMsg:AppConfig.ActivityForm.CreationErrorMessage +"Failed Repsonse"
                });
            }
        }).catch(error => {
            this.setState({
                SaveFlag:false,
                showErrorMsg: true,
                actionType:"Failed",
                errorMsg:AppConfig.ActivityForm.CreationErrorMessage +error
            });
            console.log(error);
        });
    }
   
    getShiftNames = (activityDate:any, selectedShifts:number[]):string =>{
        const {ShiftTimmings} = this.state;
        let shiftString:string = "";
        let shiftTimings = SyncService.getShiftTimingsArr( activityDate, selectedShifts, ShiftTimmings);
        let clubbedShifts  = SyncService.clubShiftTimings(shiftTimings);
        let shiftsArr:string[]  = [];
        for (let s = 0; s < clubbedShifts.length; s++) {
            const shift = clubbedShifts[s];
            let str =shift.STime+"-"+shift.ETime;
            shiftsArr.push(str); 
        }        
        shiftString = shiftsArr.join(", ");
        return shiftString;
    }
    getLocationNames = (selectedLocations:number[]):string =>{
        let locationString:string = "";
        const {Locations} = this.state;
        let locArr = _.map(Locations.filter((s)=>{
            return selectedLocations.indexOf(s.LocationId) !== -1;
        }),'LocationName');
        locationString = locArr.join(", ");
        return locationString;
    } 
    getFormattedEmail = (userArr:IUserPersonaField):string =>{
        let locationString:string = "";
        locationString = "<a href='mailto:"+userArr.Email+"'>"+userArr.DisplayName+"</a>"
        return locationString;
    }
    formVisitorEmailBody = (newActivity:IActivity):string=>{
        var html:string = "", border = "text-align:center;border-bottom:solid 1px #000000;",
        borderLeftTop= "padding:8px 5px;border-top:solid 1px #000000;border-left: solid 1px #000000;", 
        borderRightSide = "border-right:solid 1px #000000;";
        let  visitorEmailObj:IUserPersonaField = {} as IUserPersonaField; 
        visitorEmailObj.Email = newActivity.visitorEmail;
        visitorEmailObj.DisplayName = newActivity.visitorEmail.split('@')[0];
        html += '<table style="'+ border +'" cellpadding="2" cellspacing="0"  >';
        html+='<tr style="border-bottom: solid 1px #000000;"><td style="'+borderLeftTop+'"><b>Name</b></td><td style="'+borderLeftTop+borderRightSide+'">'+newActivity.activityName+'</td></tr>';
        html+='<tr style="border-bottom: solid 1px #000000;"><td style="'+borderLeftTop+'"><b>Date</b></td><td style="'+borderLeftTop+borderRightSide+'">'+formatDateWithFrmt(newActivity.activityDate,"MM/DD/YYYY")+'</td></tr>';
        html+='<tr style="border-bottom: solid 1px #000000;"><td style="'+borderLeftTop+'"><b>Shift</b></td><td style="'+borderLeftTop+borderRightSide+'">'+this.getShiftNames(newActivity.activityDate, newActivity.selectedShiftIds)+'</td></tr>';
        html+='<tr style="border-bottom: solid 1px #000000;"><td style="'+borderLeftTop+'"><b>Location</b></td><td style="'+borderLeftTop+borderRightSide+'">'+this.getLocationNames(newActivity.selectedLocations)+'</td></tr>';
        html+='<tr style="border-bottom: solid 1px #000000;"><td style="'+borderLeftTop+'"><b>BookedFor</b></td><td style="'+borderLeftTop+borderRightSide+'">'+this.getFormattedEmail(visitorEmailObj)+'</td></tr>';
        html+='<tr style="border-bottom: solid 1px #000000;"><td style="'+borderLeftTop+'"><b>BookedBy</b></td><td style="'+borderLeftTop+borderRightSide+'">'+this.getFormattedEmail(newActivity.bookedByEmail)+'</td></tr>';        
        html+='</table>';
        return html;
    }
}